import * as actionTypes from './actionTypes';
import Axios from '../../axios-symfony';

const purchaseSuccess = (saleData, saleId) => {
	return {
		type: actionTypes.PURCHASE_SUCCESS,
		saleData: saleData,
		saleId: saleId,
	}
}

const purchaseFailed = error => {
	return {
		type: actionTypes.PURCHASE_FAILED,
		error: error
	}
}

const purchaseStart = () => {
	return {
		type: actionTypes.PURCHASE_START
	}
}

export const newPurchase = (saleData) => {
	return (dispatch, getState) => {
		dispatch(purchaseStart());
		const currentState = getState();
		const token = currentState.auth.token;
		const config = {
			headers: { Authorization: `Bearer ${token}` }
		};
		Axios.post('/sales', saleData, config)
			.then(response => {
				// console.log(response);
				dispatch(purchaseSuccess(saleData, response.data.id));
			})
			.catch(error => {
				console.log(error);
				dispatch(purchaseFailed(error));
			})
	}
}

export const purchaseReset = () => {
	return {
		type: actionTypes.PURCHASE_RESET
	}
}