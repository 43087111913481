import React from 'react';
import classes from './LoadingOverlay.module.scss';
import Spinner from '../Spinner/Spinner';
const LoadingOverlay = () => {
	return (
		<div className={classes.LoadingOverlay}>
			<Spinner />
		</div>
	);
};

export default LoadingOverlay;