import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const ProductRemove = (props) => {
	return (
		<Button variant="danger" onClick={() => props.productRemove(props.id, props.isAuth)}><FontAwesomeIcon icon={faTimesCircle} /></Button>
	);
};

export default ProductRemove;