import React from 'react';
import { Card } from 'react-bootstrap';
import PriceFormat from '../UI/PriceFormat/PriceFormat';
import Spinner from '../UI/Spinner/Spinner';

const CheckoutSummary = (props) => {
	return (
		<Card
			bg="light"
			text="dark"
		>
			<Card.Header>Riepilogo Ordine</Card.Header>
			<Card.Body>
				{props.loading
					? <Spinner />
					: <div className="small text-muted">
						<p><b>Totale parziale</b>: <PriceFormat>{props.subTotal}</PriceFormat></p>
						{props.totalShipping ? <p><b>Spedizione</b>: <PriceFormat>{props.totalShipping}</PriceFormat></p> : null}
						<p><b>Tasse</b>: <PriceFormat>{props.totalTaxes}</PriceFormat></p>
						<p><b>Totale</b>: <PriceFormat>{props.totalPrice}</PriceFormat></p>
					</div>
				}

			</Card.Body>
		</Card>
	);
};

export default CheckoutSummary;