import React from 'react';
import InputList from '../../UI/InputList/InputList';
import { Form } from 'react-bootstrap';
import Spinner from '../../UI/Spinner/Spinner';

const AddressForm = (props) => {
	let form = <InputList controls={props.controls} inputChange={props.inputChange} />

	if (props.loading) {
		form = <Spinner />
	}

	let errorMessage = null;

	if (props.error) {
		errorMessage = (
			<p>{props.error.message}</p>
		);
	}

	return (
		<div>
			{errorMessage}
			<Form onSubmit={props.addressFormSubmit}>
				{form}
			</Form>
		</div>
	);
};

export default AddressForm;