import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import * as actions from '../../../store/actions/index';

class CheckoutSuccess extends Component {

	state = {
		redirect: false,
		saleId: null
	}

	componentDidMount() {
		this.resetAll();
	}

	resetAll() {
		if (this.props.sale && this.props.purchased) {
			this.setState({
				saleId: this.props.sale.id
			});
			this.props.onCartReset(this.props.isAuthenticated);
			this.props.onCheckoutReset();
			this.props.onPurchaseReset();
		} else {
			this.setState({
				redirect: true
			});
		}
	}

	render() {
		return (
			<Row>
				{this.state.redirect ? <Redirect to="/" /> : null}
				<Col className="text-center">
					<FontAwesomeIcon className="text-success" icon={faCheckCircle} size="6x" />
					<h1 className="mt-4">Grazie per il tuo acquisto!</h1>
					<p>Il tuo ordine è il numero <b>{this.state.saleId}</b></p>
					<p className="text-muted">Riceverai una email di conferma dell'ordine con tutti i dettagli e un link per tracciare i progressi.</p>
					<Link to="/" className="btn btn-primary">Continua gli acquisti</Link>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		sale: state.sale.sale,
		purchased: state.sale.purchased,
		isAuthenticated: state.auth.token !== null
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onCartReset: (isAuth) => dispatch(actions.cartReset(isAuth)),
		onCheckoutReset: () => dispatch(actions.checkoutReset()),
		onPurchaseReset: () => dispatch(actions.purchaseReset()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccess);