import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';

const CheckoutButton = () => {
	return (
		<LinkContainer to="/checkout">
			<Button className="border-radius-t-0 w-100" variant="primary">Checkout</Button>
		</LinkContainer>
	);
};

export default CheckoutButton;