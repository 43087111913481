import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	showModal: false,
	loading: false,
	paymentAddress: null,
	paymentMethods: null,
	paymentMethod: null,
	shippingAddress: null,
	shippingMethods: null,
	shippingMethod: null,
	totalShipping: 0,
	totalProducts: 0,
	totalTaxes: 0,
	subTotal: 0,
	total: 0,
	paymentDataForShipping: true
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_PAYMENT_ADDRESS:
			return updateObject(state, {
				paymentMethods: action.payments,
				paymentAddress: +action.addressId,
			});
		case actionTypes.CHECKOUT_SHIPPINGS_START:
			return updateObject(state, {
				shippingMethods: null,
				shippingMethod: null,
			});
		case actionTypes.CHECKOUT_SHIPPINGS_SUCCESS:
			return updateObject(state, {
				shippingMethods: action.shippings,
				shippingAddress: +action.addressId
			});
		case actionTypes.SET_SHIPPING_METHOD:
			return updateObject(state, { shippingMethod: action.methodId });
		case actionTypes.SET_PAYMENT_METHOD:
			return updateObject(state, { paymentMethod: action.methodId });
		case actionTypes.SHOW_MODAL_NEW_ADDRESS:
			return updateObject(state, { showModal: action.show });
		case actionTypes.TOGGLE_PAYMENT_DATA_SHIPPING:
			return updateObject(state, {
				paymentDataForShipping: !state.paymentDataForShipping,
				shippingMethods: null,
				shippingMethod: null,
				shippingAddress: !state.paymentDataForShipping ? state.paymentAddress : null
			});
		case actionTypes.CHECKOUT_TOTALS_START: return updateObject(state, { loading: true });
		case actionTypes.CHECKOUT_TOTALS_SUCCESS: return updateObject(state, {
			loading: false,
			totalShipping: action.totals.totalShipping,
			totalProducts: action.totals.totalProducts,
			totalTaxes: action.totals.totalTaxes,
			subTotal: action.totals.subTotal,
			total: action.totals.total,
		});

		case actionTypes.CHECKOUT_RESET: return initialState;
		default:
			return state;
	}
}

export default reducer;