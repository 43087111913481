import React from 'react';
import { Card } from 'react-bootstrap';
import Input from '../../../UI/Input/Input';
import PriceFormat from '../../../UI/PriceFormat/PriceFormat';
const ShippingMethod = (props) => {
	let options = [];
	if (props.methods) {
		options = props.methods.map(method => {
			let price = (
				<span>
					{method.shipping.name}{' '}-{' '}<PriceFormat>{method.price}</PriceFormat>
				</span>
			);
			return (
				{
					label: price,
					value: method.id
				}
			);
		}
		);
	}

	let shippings = null;

	shippings = (
		<Input
			elementType="radio"
			name="shippingmethodinput"
			id="shippingmethodinput"
			elementConfig={{
				options: options,
				label: 'Scegli un metodo di spedizione',
			}}
			changed={(event) => props.methodChange(event.target.value)}
			shouldValidate="false"
		/>
	);

	if (!props.methods || props.methods.length === 0) {
		shippings = <p>Non sono presenti modalità di consegna per l'indirizzo selezionato</p>;
	}

	return (
		<Card>
			<Card.Body>
				{shippings}
			</Card.Body>
		</Card>
	);
};

export default ShippingMethod;