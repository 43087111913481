import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";

const Paypal = (props) => {
	return (
		<PayPalButton
			options={{
				clientId: props.clientId,
				commit: false,
				disableFunding: 'card',
				currency: "EUR"
			}}
			createOrder={(data, actions) => {
				return actions.order.create({
					purchase_units: [{
						amount: {
							value: props.amount
						}
					}]
				});
			}}
			onApprove={(data, actions) => {
				// Capture the funds from the transaction
				return actions.order.capture().then(function (details) {
					props.createSale();
				});
			}}
		/>
	);
};

export default Paypal;