import React from 'react';
import { Alert } from 'react-bootstrap';
import { Transition, TransitionGroup } from 'react-transition-group';
import classes from './CartAlert.module.scss';

const CartAlert = (props) => {
	const alerts = props.alerts.map((alert) => {
		let text;
		switch (alert.type) {
			case 'add':
				text = 'item added to cart';
				break;
			case 'danger':
				text = 'item removed from cart';
				break;
			case 'warning':
				text = 'item decrement qty to cart';
				break;
			default:
				text = 'item added to cart';
		}

		return (
			<Transition
				in
				timeout={400}
				mountOnEnter
				unmountOnExit
				onEntered={() => setTimeout(() => { props.dismiss(alert.index) }, 3000)}
				key={alert.index}
			>
				{state =>
					<Alert
						style={{
							transition: "transform 0.4s, opacity 0.4s",
							opacity: state === "entering" && state !== "exiting" ? 0 : 1,
							transform: state !== "entering" && state !== "exiting" ? "translateX(0px)" : "translateX(400px)"
						}}
						onClose={() => props.dismiss(alert.index)} variant={alert.type} dismissible>
						<div>
							{text}
						</div>
					</Alert>
				}
			</Transition>
		);
	});
	return (
		<div className={classes.Alert}>
			<TransitionGroup>
				{alerts}
			</TransitionGroup>
		</div>
	);
};

export default CartAlert;