import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	token: null,
	userId: null,
	error: null,
	violations: null,
	needValidation: false,
	loading: false,
	errorValidation: null,
	violationsValidation: null,
	loadingValidation: false,
	authRedirectPath: '/'
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_START:
			return updateObject(state, { error: null, violations: null, loading: true });
		case actionTypes.AUTH_FAIL:
			return updateObject(state, { error: action.error, violations: action.violations, loading: false });
		case actionTypes.AUTH_SUCCESS:
			return updateObject(state, {
				token: action.token,
				userId: action.userId,
				error: null,
				needValidation: false,
				violations: null,
				violationsValidation: null,
				loading: false
			});
		case actionTypes.AUTH_VALIDATION_START: return updateObject(state, { errorValidation: null, loadingValidation: true });
		case actionTypes.AUTH_VALIDATION_FAIL:
			return updateObject(state, {
				errorValidation: action.error,
				violationsValidation: (action.error.response.data.violations ? action.error.response.data.violations : state.violationsValidation),
				loadingValidation: false
			});
		case actionTypes.AUTH_VALIDATION_SUCCESS:
			return updateObject(state, {
				token: action.token,
				userId: action.userId,
				errorValidation: null,
				needValidation: false,
				violations: null,
				violationsValidation: null,
				loadingValidation: false
			});
		case actionTypes.AUTH_NEED_VALIDATION:
			return updateObject(state, {
				token: null,
				userId: action.userId,
				error: null,
				violations: null,
				needValidation: true,
				loading: false
			});
		case actionTypes.AUTH_RESET_ERROR: return updateObject(state, { error: null, violations: null });
		case actionTypes.AUTH_LOGOUT: return updateObject(state, { token: null, userId: null });
		case actionTypes.SET_AUTH_REDIRECT_AUTH: return updateObject(state, { authRedirectPath: action.path });
		default:
			return state;
	}
}

export default reducer;