import React, { Component } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Axios from '../../../axios-symfony';

class LayoutSidebar extends Component {

	state = {
		menuSide: null
	}
	componentDidMount() {
		Axios.get('/menus?position=side')
			.then(response => {
				if (response.data['hydra:member'][0]) {

					const items = response.data['hydra:member'][0]['menuItems'];
					const menuSide = items.map(item => (
						{
							id: item[item.type].id,
							name: item[item.type].name,
							type: item.type
						}
					));
					this.setState({
						menuSide: menuSide
					});
				} else {
					this.setState({
						menuSide: false
					});
				}
			})
			.catch(error => {
				console.log(error);

			});
	}

	render() {
		let listItems = null;
		if (this.state.menuSide) {
			listItems = this.state.menuSide.map(item => (
				<LinkContainer key={item.id} to={"/" + item.type + "/" + item.id}>
					<ListGroup.Item as="a" variant="info">{item.name}</ListGroup.Item>
				</LinkContainer>
			));
		}

		return (
			<Row>
				<Col lg="3">
					<ListGroup>
						{listItems}
					</ListGroup>
				</Col>
				<Col lg="9">
					{this.props.content}
				</Col>
			</Row>
		);
	}
}

export default LayoutSidebar;