import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Col, Row, Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import GlobalSpinner from '../../components/UI/Spinner/Spinner';
import CheckoutSummary from '../../components/Checkout/CheckoutSummary';
import ShippingInfo from '../../components/Checkout/ShippingInfo/ShippingInfo';
import { checkValidity } from '../../shared/validation';
import { updateObject, checkViolations } from '../../shared/utility';
import CheckoutLogin from './CheckoutLogin/CheckoutLogin';
import PaymentInfo from '../../components/Checkout/PaymentInfo/PaymentInfo';
import AddressModal from '../../components/Checkout/AddressModal/AddressModal';
import SaleButton from '../../components/Checkout/SaleButton/SaleButton';
import LoadingOverlay from '../../components/UI/LoadingOverlay/LoadingOverlay';
import Axios from '../../axios-symfony';

class Checkout extends Component {

	initialAddressControls = {
		firstname: {
			elementType: 'input',
			elementConfig: {
				label: 'Nome',
				type: 'text',
				placeholder: 'Nome',
			},
			rules: {
				required: true,
				minLength: 3,
			},
			errorText: 'Il nome deve avere almeno 3 caratteri',
			valid: false,
			touched: false,
			value: ''
		},
		lastname: {
			elementType: 'input',
			elementConfig: {
				label: 'Cognome',
				type: 'text',
				placeholder: 'Cognome',
			},
			rules: {
				required: true,
				minLength: 3,
			},
			errorText: 'Il cognome deve avere almeno 3 caratteri',
			valid: false,
			touched: false,
			value: ''
		},
		phone: {
			elementType: 'input',
			elementConfig: {
				label: 'Telefono',
				type: 'text',
				placeholder: 'Telefono',
			},
			rules: {
				required: true,
				minLength: 6,
			},
			errorText: 'Il telefono deve avere almeno 6 caratteri',
			valid: false,
			touched: false,
			value: ''
		},
		address: {
			elementType: 'input',
			elementConfig: {
				type: 'text',
				label: 'Indirizzo e numero civico',
				placeholder: 'Indirizzo'
			},
			rules: {},
			valid: false,
			touched: false,
			value: ''
		},
		postcode: {
			elementType: 'input',
			elementConfig: {
				type: 'text',
				label: 'Codice Postale',
				placeholder: 'Codice Postale'
			},
			rules: {
				isNumeric: true
			},
			valid: false,
			touched: false,
			value: ''
		},
		city: {
			elementType: 'input',
			elementConfig: {
				type: 'text',
				label: 'Città',
				placeholder: 'Città'
			},
			rules: {},
			valid: false,
			touched: false,
			value: ''
		},
		zone: {
			elementType: 'select',
			elementConfig: {
				label: 'Provincia',
				options: []
			},
			rules: {},
			valid: false,
			touched: false,
			value: ''
		}
	}

	state = {
		loadedAddresses: null,
		addressControls: {
			...this.initialAddressControls
		}
	}

	componentDidMount() {
		if (!this.props.isAuthenticated) {
			this.props.onSetAuthRedirectPath('/checkout');
		}
		this.fetchAddresses();

		Axios.get('/zones')
			.then(response => {

				const zoneOptions = response.data['hydra:member'].map(address => (
					{
						value: address.id,
						displayValue: address.name
					}
				));
				zoneOptions.unshift({
					value: '',
					displayValue: 'seleziona una provincia'
				});
				const updatedControls = updateObject(this.state.addressControls, {
					zone: updateObject(this.state.addressControls.zone, {
						elementConfig: updateObject(this.state.addressControls.zone.elementConfig, {
							options: zoneOptions
						}),
					})
				});
				this.setState({
					addressControls: updatedControls
				})
				this.initialAddressControls = updatedControls;
			});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.addresses && this.props.addresses && prevProps.addresses.length !== this.props.addresses.length) {
			this.resetAddressFormHandler();
		}
		this.fetchAddresses();
		this.controlsViolations(prevProps);
	}

	controlsViolations(prevProps) {

		const updatedControls = checkViolations(prevProps.violations, this.props.violations, this.state.addressControls);
		if (updatedControls) {
			this.setState({
				addressControls: updatedControls,
			})
		}
	}

	fetchAddresses = () => {
		if (this.props.isAuthenticated && !this.state.loadedAddresses) {
			this.setState({
				loadedAddresses: true
			});
			this.props.onFetchAddresses();
		}
	}

	paymentAddressChangeHandler = (event) => {
		this.props.onSetPaymentAddress(event.target.value);
		if (this.props.paymentDataForShipping) {
			this.shippingAddressChangeHandler(event);
		}
	}

	shippingAddressChangeHandler = (event) => {

		const zoneId = this.getZoneByAddress(event.target.value);
		this.props.onSetShippingAddress(event.target.value, this.props.cartItems, zoneId);
	}

	paymentDataShippingHandler = () => {
		if (!this.props.paymentDataForShipping) {
			if (this.props.paymentAddress) {
				const zoneId = this.getZoneByAddress(this.props.paymentAddress);
				this.props.onSetShippingAddress(this.props.paymentAddress, this.props.cartItems, zoneId);
			}
		}
		this.props.onTogglePaymentDataShipping();
	}

	getZoneByAddress = (address) => {
		var zoneId = null;
		this.props.addresses.forEach(addr => {
			if (+addr.id === +address) {
				zoneId = addr.zone.id;
			}
		});
		return zoneId;
	}

	resetAddressFormHandler = () => {
		this.props.onShowModalNewAddress(false);
		this.setState({
			addressControls: {
				...this.initialAddressControls
			}
		});
	}

	inputChangedHandler = (event, controlName) => {
		const updatedControls = updateObject(this.state.addressControls, {
			[controlName]: updateObject(this.state.addressControls[controlName], {
				value: event.target.value,
				valid: checkValidity(event.target.value, this.state.addressControls[controlName].rules),
				touched: true
			})
		});

		this.setState({
			addressControls: updatedControls
		})
	}

	newAddressFormHandler = (event) => {
		event.preventDefault();
		const formData = {};

		for (let name in this.state.addressControls) {
			formData[name] = this.state.addressControls[name].value;
		}

		formData.zone = '/api/zones/' + this.state.addressControls.zone.value

		this.props.onAddNewAddress(formData);
	}

	createSaleHandler = () => {

		const saleData = {};

		//handle comment
		saleData['comment'] = '';
		saleData['paymentAddressId'] = this.props.paymentAddress;
		saleData['shippingAddressId'] = this.props.shippingAddress;
		saleData['shippingMethod'] = this.props.shippingMethod;
		saleData['paymentMethod'] = this.props.paymentMethod;

		saleData['products'] = this.props.cartItems.map(product => (
			{ product: product.id, quantity: product.qty }
		));

		this.props.onNewPurchase(saleData);
	}

	render() {
		let checkout = null;
		if (this.props.cartLoading && this.props.cartItems.length === 0) {
			checkout = <GlobalSpinner />
		}

		if (this.props.cartItems.length > 0) {
			if (this.props.isAuthenticated) {
				let saleButton = null;
				if (this.props.readyPurchase) {
					saleButton = <SaleButton
						total={this.props.totalPrice}
						paymentMethod={this.props.paymentMethod}
						paymentMethods={this.props.paymentMethods}
						createSale={this.createSaleHandler}
					>Completa Ordine</SaleButton>
				}
				if (this.props.saleLoading) {
					saleButton = <SaleButton disabled><Spinner as="span" size="sm" animation="grow" role="status" /> Caricamento...</SaleButton>
				}
				checkout = (
					<Row>
						<Col lg="9">
							{this.props.saleLoading ? <LoadingOverlay /> : null}
							{this.props.showModal ? <AddressModal
								onShowModal={this.props.onShowModalNewAddress}
								addressControls={this.state.addressControls}
								inputChange={this.inputChangedHandler}
								loading={this.props.addressLoading}
								newAddressForm={this.newAddressFormHandler}
							/> : null}
							<PaymentInfo
								addresses={this.props.addresses}
								addressChange={this.paymentAddressChangeHandler}
								loading={this.props.addressLoading}
								addAddress={this.props.onShowModalNewAddress}
								isSetPaymentAddress={this.props.isSetPaymentAddress}
								methodChange={this.props.onSetPaymentMethod}
								paymentMethods={this.props.paymentMethods}
							/>
							<ShippingInfo
								addresses={this.props.addresses}
								addressChange={this.shippingAddressChangeHandler}
								loading={this.props.addressLoading}
								addAddress={this.props.onShowModalNewAddress}
								isSetShippingAddress={this.props.isSetShippingAddress}
								methodChange={this.props.onSetShippingMethod}
								shippingMethods={this.props.shippingMethods}
								isChecked={this.props.paymentDataForShipping}
								changeCheckbox={this.paymentDataShippingHandler}
							/>
						</Col>
						<Col lg="3">
							<CheckoutSummary
								totalShipping={this.props.totalShipping}
								totalTaxes={this.props.totalTaxes}
								subTotal={this.props.subTotal}
								totalPrice={this.props.totalPrice}
								loading={this.props.checkoutLoading}
							/>
							{saleButton}
						</Col>
					</Row>
				)
			} else {
				checkout = (
					<CheckoutLogin />
				)
			}
		}

		if (this.props.cartItems.length === 0 && !this.props.cartLoading) {
			checkout = <Redirect to="/cart" />;
		}

		if (this.props.purchased) {
			checkout = <Redirect to="/checkout/success" />;
		}

		return checkout;
	}
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.token !== null,

		addresses: state.address.addresses,
		violations: state.address.violations,
		addressLoading: state.address.loading,

		isSetShippingAddress: state.checkout.shippingAddress !== null,
		isSetPaymentAddress: state.checkout.paymentAddress !== null,
		shippingMethods: state.checkout.shippingMethods,
		shippingMethod: state.checkout.shippingMethod,
		paymentMethods: state.checkout.paymentMethods,
		readyPurchase: state.checkout.shippingAddress && state.checkout.paymentAddress && state.checkout.paymentMethod && state.checkout.shippingMethod,
		paymentAddress: state.checkout.paymentAddress,
		shippingAddress: state.checkout.shippingAddress,
		paymentMethod: state.checkout.paymentMethod,
		showModal: state.checkout.showModal,
		paymentDataForShipping: state.checkout.paymentDataForShipping,

		purchased: state.sale.purchased,
		saleLoading: state.sale.loading,

		cartItems: state.cart.items,
		cartLoading: state.cart.loading,
		checkoutLoading: state.checkout.loading,
		totalShipping: state.checkout.totalShipping,
		totalTaxes: state.checkout.totalTaxes,
		subTotal: state.checkout.subTotal,
		totalPrice: state.checkout.total
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchAddresses: () => dispatch(actions.fetchAddresses()),
		onTogglePaymentDataShipping: () => dispatch(actions.togglePaymentDataShipping()),
		onSetPaymentAddress: (addressId) => dispatch(actions.setPaymentAddress(addressId)),
		onSetShippingAddress: (addressId, cartItems, zoneId) => dispatch(actions.setShippingAddress(addressId, cartItems, zoneId)),
		onSetShippingMethod: (shippingRateId) => dispatch(actions.setShippingMethod(shippingRateId)),
		onSetPaymentMethod: (paymentId) => dispatch(actions.setPaymentMethod(paymentId)),
		onAddNewAddress: (addressData) => dispatch(actions.addNewAddress(addressData)),
		onShowModalNewAddress: (show) => dispatch(actions.showModalNewAddress(show)),
		onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
		onNewPurchase: (saleData) => dispatch(actions.newPurchase(saleData))
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Checkout);