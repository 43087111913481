import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	addresses: [],
	violations: null,
	loading: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_ADDRESSES_START:
			return updateObject(state, { loading: true });
		case actionTypes.FETCH_ADDRESSES_SUCCESS:
			return updateObject(state, { loading: false, addresses: action.addresses });
		case actionTypes.ADD_NEW_ADDRESS:
			const newAddresses = [...state.addresses];
			newAddresses.push(action.newAddressData);
			return updateObject(state, { addresses: newAddresses, loading: false });
		case actionTypes.FETCH_ADDRESSES_FAILED:
			return updateObject(state, { loading: false, violations: action.error });
		default:
			return state;
	}
}

export default reducer;