import React from 'react';
import { Card, Badge, Button } from 'react-bootstrap';
import AddressSelect from '../AddressSelect/AddressSelect';
import ShippingMethod from './ShippingMethod/ShippingMethod';
import Input from '../../UI/Input/Input';
const ShippingInfo = (props) => {
	return (
		<Card className="mb-3">
			<Card.Header as="h3">
				<Badge variant="secondary">3</Badge> Informazioni spedizione
			</Card.Header>
			<Card.Body>
				<Input
					elementType="checkbox"
					id="checkshipping"
					elementConfig={{
						options: [{
							label: 'Utilizza indirizzo di spedizione per dati pagamento.',
							value: 1,
							checked: props.isChecked,
						}],
						label: '',
					}}
					changed={() => props.changeCheckbox()}
					shouldValidate="false"
				/>
				{!props.isChecked ? <AddressSelect loading={props.loading} selectId="radioshipping" addressChange={props.addressChange} addresses={props.addresses} /> : null}

				{props.isSetShippingAddress ? <ShippingMethod methods={props.shippingMethods} methodChange={props.methodChange} /> : null}
			</Card.Body>
			<Card.Footer>
				<Button variant="secondary" size="sm" onClick={() => props.addAddress(true)} >Aggiungi nuovo indirizzo</Button>
			</Card.Footer>
		</Card>
	);
};

export default ShippingInfo;