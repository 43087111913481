import React, { Component } from 'react';
import Input from '../../../components/UI/Input/Input';
import { updateObject, checkViolations } from '../../../shared/utility';
import { checkValidity } from '../../../shared/validation';
import { Button, Form } from 'react-bootstrap';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';

class AuthValidationForm extends Component {

	state = {
		controls: {
			confirmationToken: {
				elementType: 'input',
				elementConfig: {
					label: 'Specifica il codice di 6 caratteri che hai ricevuto sulla email',
					type: 'text',
					placeholder: '',
				},
				rules: {
					required: true,
					minLength: 6,
					maxLength: 6,
				},
				errorText: 'Il codice è composto da 6 caratteri',
				valid: false,
				touched: false,
				value: ''
			}
		}
	}

	codeChangeHandler = (event) => {
		const controlName = 'confirmationToken';
		const updatedControls = updateObject(this.state.controls, {
			[controlName]: updateObject(this.state.controls[controlName], {
				value: event.target.value,
				valid: checkValidity(event.target.value, this.state.controls[controlName].rules),
				touched: true
			})
		});

		this.setState({
			controls: updatedControls
		})
	}

	verifyCodeHandler = (event) => {
		event.preventDefault();
		this.props.onVerifyCode(this.props.userId, this.state.controls.confirmationToken.value);
	}

	componentDidUpdate(prevProps) {
		const updatedControls = checkViolations(prevProps.violations, this.props.violations, this.state.controls);
		if (updatedControls) {
			this.setState({
				controls: updatedControls,
			})
		}
	}

	render() {
		let errorMessage = null;
		if (this.props.error && !this.props.violations) {
			errorMessage = (
				<p className="text-danger">Il codice di verifica che hai inserito non sembra valido.</p>
			);
		}

		return (
			<div className="text-center">
				{errorMessage}
				<h3>Inserisci il codice di verifica</h3>
				<Form onSubmit={this.verifyCodeHandler}>
					<Input
						{...this.state.controls.confirmationToken}
						changed={(event) => this.codeChangeHandler(event)}
						shouldValidate="true"
					/> 
					<Button  disabled={!this.state.controls.confirmationToken.valid} variant="primary" type="submit">Verifica</Button>
				</Form>
				<p className="text-muted text-left"><b>Hai problemi?</b> a volte sono necessari alcuni minuti per ricevere il codice di verifica. Controlla anche nello Spam della tua casella email.</p>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.auth.loadingValidation,
		error: state.auth.errorValidation,
		violations: state.auth.violationsValidation,
		userId: state.auth.userId,
		needValidation: state.auth.needValidation
		// authRedirectPath: state.auth.authRedirectPath,
		// isAuthenticated: state.auth.token !== null
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		onVerifyCode: (userId, code) => dispatch(actions.authVerifyCode(userId, code)),
		onResetError: () => dispatch(actions.authResetError()),
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(AuthValidationForm);