import React, { Component } from 'react';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { Row, Col, Card, Button, Form, Modal } from 'react-bootstrap';
import { updateObject, checkViolations } from '../../../shared/utility';
import { checkValidity } from '../../../shared/validation';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import Axios from '../../../axios-symfony';
import InputList from '../../../components/UI/InputList/InputList';
import AuthValidationForm from '../AuthValidationForm/AuthValidationForm';
import { Redirect } from 'react-router-dom';

class Register extends Component {

	initialControls = {
		firstname: {
			elementType: 'input',
			elementConfig: {
				label: 'Nome',
				type: 'text',
				placeholder: 'Nome',
			},
			rules: {
				required: true,
				minLength: 3,
			},
			errorText: 'Il nome deve avere almeno 3 caratteri',
			valid: false,
			touched: false,
			value: ''
		},
		lastname: {
			elementType: 'input',
			elementConfig: {
				label: 'Cognome',
				type: 'text',
				placeholder: 'Cognome',
			},
			rules: {
				required: true,
				minLength: 3,
			},
			errorText: 'Il cognome deve avere almeno 3 caratteri',
			valid: false,
			touched: false,
			value: ''
		},
		phone: {
			elementType: 'input',
			elementConfig: {
				label: 'Telefono',
				type: 'text',
				placeholder: 'Telefono',
			},
			rules: {
				required: true,
				minLength: 6,
			},
			errorText: 'Il telefono deve avere almeno 6 caratteri',
			valid: false,
			touched: false,
			value: ''
		},
		email: {
			elementType: 'input',
			elementConfig: {
				label: 'Indirizzo Email',
				type: 'email',
				placeholder: 'Indirizzo Email',
			},
			rules: {
				required: true,
				isEmail: true,
			},
			errorText: 'Formato Email non valido',
			valid: false,
			touched: false,
			value: ''
		},
		password: {
			elementType: 'password',
			elementConfig: {
				label: 'Password',
				type: 'password',
				placeholder: 'Password'
			},
			rules: {
				required: true,
				isPassword: true
			},
			instruction: 'La password deve contenuere almeno 7 caratteri almeno una lettera, una lettera maiuscola e un numero',
			valid: false,
			touched: false,
			value: ''
		},
		retypedPassword: {
			elementType: 'password',
			elementConfig: {
				label: 'Ripeti Password',
				type: 'password',
				placeholder: 'Ripeti Password'
			},
			rules: {
				required: true,
			},
			valid: false,
			touched: false,
			value: ''
		}
	}

	state = {
		controls: {
			...this.initialControls
		}
	}

	inputChangedHandler = (event, controlName) => {
		const updatedControls = updateObject(this.state.controls, {
			[controlName]: updateObject(this.state.controls[controlName], {
				value: event.target.value,
				valid: checkValidity(event.target.value, this.state.controls[controlName].rules),
				touched: true
			})
		});

		this.setState({
			controls: updatedControls
		})
	}

	togglePasswordHandler = (controlName) => {
		const updatedControls = updateObject(this.state.controls, {
			[controlName]: updateObject(this.state.controls[controlName], {
				elementConfig: updateObject(this.state.controls[controlName].elementConfig, {
					type: this.state.controls[controlName].elementConfig.type === 'password' ? 'text' : 'password'
				})
			})
		});

		this.setState({
			controls: updatedControls
		});
	}

	registerHandler = (event) => {
		event.preventDefault();

		const registerData = {};

		for (let name in this.state.controls) {
			registerData[name] = this.state.controls[name].value;
		}

		this.props.onRegister(registerData);
	}


	componentDidUpdate(prevProps) {
		// console.log('[Register.js] componentDidUpdate', this.props.error);
		if (this.props.needValidation && prevProps.needValidation !== this.props.needValidation) {
			this.setState({
				controls: {
					...this.initialControls
				}
			});
		}

		const updatedControls = checkViolations(prevProps.violations, this.props.violations, this.state.controls);
		if (updatedControls) {
			this.setState({
				controls: updatedControls,
			})
		}

	}

	render() {
		let form = <InputList controls={this.state.controls} inputChange={this.inputChangedHandler} togglePassword={this.togglePasswordHandler} />

		if (this.props.loading) {
			form = <Spinner />
		}

		let errorMessage = null;

		if (this.props.error) {
			errorMessage = (
				<p>{this.props.error.message}</p>
			);
		}

		let authValidationForm = null;

		let authRedirect = null;
		if (this.props.isAuthenticated) {
			authRedirect = <Redirect to={this.props.authRedirectPath} />
		}

		if (this.props.needValidation) {
			authValidationForm = (
				<Modal show>
					<Modal.Body>
						<AuthValidationForm />
					</Modal.Body>
				</Modal>
			);
		}

		return (
			<Row>
				<Col lg={{ span: 8, offset: 2 }}>
					{authRedirect}
					{authValidationForm}
					<Card>
						<Card.Body>
							<h4 className="h4 text-secondary mb-4">Registrazione!</h4>
							{errorMessage}
							<Form onSubmit={this.registerHandler}>
								{form}
								{/* <Form.Group controlId="formBasicCheckbox">
										<Form.Check type="checkbox" label="Check me out" />
									</Form.Group> */}
								<Button variant="primary" type="submit">
									Login in
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.auth.loading,
		error: state.auth.error,
		violations: state.auth.violations,
		needValidation: state.auth.needValidation,
		authRedirectPath: state.auth.authRedirectPath,
		isAuthenticated: state.auth.token !== null
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		onRegister: (registerData) => dispatch(actions.register(registerData)),
		onResetError: () => dispatch(actions.authResetError()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Register, Axios));