import React from 'react';
import Input from '../Input/Input';

const InputList = (props) => {
	const formElementsArray = [];

	for (let name in props.controls) {
		formElementsArray.push({
			...props.controls[name],
			id: name,
		})
	}
	let inputList = formElementsArray.map(formElement => (
		<Input
			key={formElement.id}
			elementType={formElement.elementType}
			elementConfig={formElement.elementConfig}
			togglePassword={props.togglePassword}
			changed={(event) => props.inputChange(event, formElement.id)}
			{...formElement}
			shouldValidate={formElement.rules}
		/>
	));

	return inputList;
};

export default InputList;