import React from 'react';
import classes from './NavigationItems.module.scss';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = (props) => {
	let listItems = null;
	if (props.menuItems) {
		listItems = props.menuItems.map(item => (
			<NavigationItem key={item.id} link={"/" + item.type + "/" + item.id} >{item.name}</NavigationItem>
		));
	}
	return (
		<div className={classes.NavigationItems} onClick={props.clickItems} >
			<NavigationItem exact link="/">Homepage</NavigationItem>
			{listItems}
		</div >
	)
};

export default navigationItems;