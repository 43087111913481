import * as actionTypes from './actionTypes';
import Axios from '../../axios-symfony';

const addToCartSuccess = (productObject, isAuth, productIndex) => {
	return {
		type: actionTypes.ADD_TO_CART_SUCCESS,
		productObject: productObject,
		isAuth: isAuth,
		productIndex: productIndex
	}
}

const cartSet = cartObject => {
	return {
		type: actionTypes.CART_SET,
		cartObject: cartObject
	}
}

const cartStart = () => {
	return {
		type: actionTypes.CART_START,
	}
}

const cartStartItemUpdate = (index) => {
	return {
		type: actionTypes.CART_START_ITEM_UPDATE,
		productIndex: index
	}
}

const cartRemoveItem = (index, isAuth) => {
	return {
		type: actionTypes.CART_REMOVE_ITEM,
		isAuth: isAuth,
		productIndex: index
	}
}

const getCurrentIndex = (items, id) => {
	return items.findIndex(item => item.id === id);
}

export const cartRemove = (productId, isAuthenticated) => {
	return (dispatch, getState) => {
		const currentState = getState();
		const productIndex = getCurrentIndex(currentState.cart.items, productId);
		if (isAuthenticated) {
			dispatch(cartStartItemUpdate(productIndex));

			const token = currentState.auth.token;
			const config = {
				headers: { Authorization: `Bearer ${token}` }
			};
			const cartRowId = currentState.cart.items[productIndex].cartId;
			Axios.delete('/cart_products/' + cartRowId, config)
				.then(response => {
					console.log(response);
					dispatch(cartRemoveItem(productIndex, isAuthenticated));
				});
		} else {
			dispatch(cartRemoveItem(productIndex, isAuthenticated));
		}
		dispatch(cartAlert('danger'));
	}
}

export const addToCart = (productObject, isAuthenticated) => {

	return (dispatch, getState) => {
		// auth if Axios
		const currentState = getState();
		const productIndex = getCurrentIndex(currentState.cart.items, productObject.id);

		dispatch(cartStartItemUpdate(productIndex));

		if (isAuthenticated) {
			const token = currentState.auth.token;
			const config = {
				headers: { Authorization: `Bearer ${token}` }
			};
			if (productIndex >= 0) {
				//PUT - Replace
				const cartRowId = currentState.cart.items[productIndex].cartId;
				// console.log(currentState);
				const productData = {
					quantity: Number(productObject.qty) + Number(currentState.cart.items[productIndex].qty),
				};
				Axios.put('/cart_products/' + cartRowId, productData, config)
					.then(response => {
						// console.log(response);
						dispatch(addToCartSuccess(productObject, isAuthenticated, productIndex));
					})
					.catch(error => {
						console.log(error);
					});
			} else {
				//POST - Insert
				const productPost = {
					product: '/api/products/' + productObject.id,
					quantity: Number(productObject.qty)
				}
				Axios.post('/cart_products', productPost, config)
					.then(response => {
						// console.log(response);
						const updatedObject = {
							cartId: response.data.id,
							...productObject
						};
						dispatch(addToCartSuccess(updatedObject, isAuthenticated, productIndex));
					});
				// console.log(productObject);
			}
		} else {
			dispatch(addToCartSuccess(productObject, isAuthenticated, productIndex));
		}
		const alertType = productObject.qty > 0 ? 'success' : 'warning';
		dispatch(cartAlert(alertType));
	}
}

const mergeCarts = (mergedCart, firstCart, secondCart) => {
	const mergeProducts = {
		...mergedCart
	};

	firstCart.forEach(elDb => {
		let quantity = elDb.qty;
		secondCart.forEach(elStorage => {
			if (elStorage.id === elDb.id) {
				//exist in both
				quantity = elStorage.qty < elDb.qty ? elDb.qty : elStorage.qty
			}
		})
		mergeProducts['product' + elDb.id] = {
			product: '/api/products/' + elDb.id,
			quantity: +quantity
		};
	});

	return mergeProducts;
}

const createCartProducts = (items) => {
	const cartProducts = items.map(item => {
		return {
			cartId: item.id,
			id: item.product.id,
			name: item.product.name,
			image: item.product.image,
			qty: item.quantity,
			price: item.product.price
		}
	});

	return {
		items: cartProducts
	}
}

export const initCart = (isAuthenticated) => {
	return (dispatch, getState) => {

		const cartStorage = localStorage.getItem('cart');
		if (isAuthenticated) {
			dispatch(cartStart());
			const currentState = getState();
			// console.log(currentState);
			const userId = currentState.auth.userId;
			const token = currentState.auth.token;
			const config = {
				headers: { Authorization: `Bearer ${token}` }
			};
			Axios.get('/customers/' + userId + '/cart_products', config)
				.then(response => {
					// console.log(response);
					// merge between localStorage cart and saved cart_products
					const cartObject = createCartProducts(response.data['hydra:member']);
					if (cartStorage) {
						//enter here only if cartStorage exists
						const cartProductsItems = cartObject ? cartObject.items : [];
						const localCart = JSON.parse(cartStorage);
						let mergeProducts = [];
						mergeProducts = mergeCarts(mergeCarts([], localCart.items, cartProductsItems), cartProductsItems, localCart.items);
						// console.log(localCart.items);
						// console.log(cartProductsItems);
						// console.log(mergeProducts);
						const mergeProductsArray = [];

						for (let index in mergeProducts) {
							mergeProductsArray.push({
								...mergeProducts[index]
							})
						}
						const customerData = {
							cartProducts: mergeProductsArray
						};
						Axios.put('/customers/' + userId + '/cart', customerData, config)
							.then(response => {
								// console.log(response);
								const cartObj = createCartProducts(response.data.cartProducts);
								dispatch(cartSet(cartObj));
								localStorage.removeItem('cart');
							})
							.catch(error => {
								console.log(error);
							});
					} else {
						dispatch(cartSet(cartObject));
					}
				})
				.catch(error => {
					console.log(error);
				});
		} else {
			if (cartStorage) {
				dispatch(cartSet(JSON.parse(cartStorage)));
			}
		}
	}
}

export const cartReset = (isAuthenticated) => {
	return (dispatch, getState) => {

		// dispatch(cartStart());
		if (isAuthenticated) {
			const currentState = getState();
			// console.log(currentState);
			const userId = currentState.auth.userId;
			const token = currentState.auth.token;
			const config = {
				headers: { Authorization: `Bearer ${token}` }
			};
			Axios.put('/customers/' + userId + '/cart', { cartProducts: [] }, config)
				.then(response => {
					// console.log(response);
					localStorage.removeItem('cart');
					dispatch(emptyCart());
				})
				.catch(error => {
					console.log(error);
				});
		} else {
			dispatch(emptyCart());
		}
	}
}

const cartAlert = (alertType) => {
	return {
		type: actionTypes.CART_ALERT,
		alertType: alertType
	}
}

export const dismissCartAlert = (alertIndex) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.DISMISS_CART_ALERT,
			alertIndex: alertIndex
		});
	}
}


export const emptyCart = () => {
	return {
		type: actionTypes.CART_EMPTY,
	}
};