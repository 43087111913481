import React from 'react';

import siteLogo from '../../assets/images/logo.png';
import classes from './Logo.module.scss';
import { Link, withRouter } from 'react-router-dom';

const logo = (props) => (
	<div className={classes.Logo} style={{ height: props.height }}>
		{props.location.pathname === '/' ? <img className="img-fluid" src={siteLogo} alt="App" /> : <Link to="/"><img className="img-fluid" src={siteLogo} alt="App" /></Link>}
	</div>
);


export default withRouter(logo);