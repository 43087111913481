import React, { Component } from 'react';
import { updateObject } from '../../../shared/utility';
import { checkValidity } from '../../../shared/validation';

import Spinner from '../../../components/UI/Spinner/Spinner';
import { Redirect } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Axios from '../../../axios-symfony';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import InputList from '../../../components/UI/InputList/InputList';

class AuthForm extends Component {
	state = {
		controls: {
			email: {
				elementType: 'input',
				elementConfig: {
					label: 'Indirizzo Email',
					type: 'email',
					placeholder: 'Indirizzo Email',
				},
				rules: {
					required: true,
					isEmail: true,
				},
				errorText: 'Formato Email non valido',
				valid: false,
				touched: false,
				value: ''
			},
			password: {
				elementType: 'password',
				elementConfig: {
					label: 'Password',
					type: 'password',
					placeholder: 'Password'
				},
				valid: false,
				touched: false,
				value: ''
			}
		}
	}


	submitHandler = (event) => {
		event.preventDefault();
		this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value);
	}

	inputChangedHandler = (event, controlName) => {
		const updatedControls = updateObject(this.state.controls, {
			[controlName]: updateObject(this.state.controls[controlName], {
				value: event.target.value,
				valid: checkValidity(event.target.value, this.state.controls[controlName].rules),
				touched: true
			})
		});

		this.setState({
			controls: updatedControls
		})
	}

	togglePasswordHandler = (controlName) => {
		const updatedControls = updateObject(this.state.controls, {
			[controlName]: updateObject(this.state.controls[controlName], {
				elementConfig: updateObject(this.state.controls[controlName].elementConfig, {
					type: this.state.controls[controlName].elementConfig.type === 'password' ? 'text' : 'password'
				})
			})
		});

		this.setState({
			controls: updatedControls
		});
	}

	render() {

		let form = <InputList controls={this.state.controls} inputChange={this.inputChangedHandler} togglePassword={this.togglePasswordHandler} />

		if (this.props.loading) {
			form = <Spinner />
		}

		let errorMessage = null;

		if (this.props.error) {
			errorMessage = (
				<p>{this.props.error.message}</p>
			);
		}

		let authRedirect = null;
		if (this.props.isAuthenticated) {
			authRedirect = <Redirect to={this.props.authRedirectPath} />
		}

		return (
			<div>
				{authRedirect}
				{errorMessage}
				<Form onSubmit={this.submitHandler}>
					{form}
					{/* <Form.Group controlId="formBasicCheckbox">
										<Form.Check type="checkbox" label="Check me out" />
									</Form.Group> */}
					<Button variant="primary" type="submit">Login</Button>
				</Form>
			</div>
		);
	}
}


const mapStateToProps = state => {
	return {
		loading: state.auth.loading,
		error: state.auth.error,
		authRedirectPath: state.auth.authRedirectPath,
		isAuthenticated: state.auth.token !== null
	}
};

const mapDispatchToProps = dispatch => {
	return {
		onAuth: (email, password) => dispatch(actions.auth(email, password)),
		onResetError: () => dispatch(actions.authResetError()),
		onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AuthForm, Axios));