import React, { Component } from 'react';
import Axios from '../../axios-symfony';
import Spinner from '../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ProductList from '../ProductList/ProductList';

class Category extends Component {

	state = {
		loadedCategory: null,
		loading: true
	}

	componentDidMount() {
		// console.log('[Category.js] componentDidMount');
		this.loadData();
	}
	componentDidUpdate(prevProps) {
		// console.log('[Category.js] componentDidUpdate');
		if (prevProps.match.params.id && prevProps.match.params.id !== this.props.match.params.id) {
			this.loadData();
		}
	}

	loadData() {
		const id = +this.props.match.params.id;
		// console.log(id);
		this.setState({
			loading: true
		})
		Axios.get('/categories/' + id)
			.then(response => {
				// console.log(response);
				// console.log(response.data.products);
				this.setState({
					loadedCategory: response.data,
					loading: false
				})
			});
	}

	render() {

		let category = null;

		if (this.state.loadedCategory) {
			category = (
				<>
					<h1 className="h2">{this.state.loadedCategory.name}</h1>
					<div>{this.state.loadedCategory.description}</div>
					<ProductList path={"/categories/" + this.state.loadedCategory.id + "/products"} />
				</>
			);
		}

		if (this.state.loading) {
			category = <Spinner />
		}

		return category;
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.token !== null
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onAddToCart: (productObject, isAuth) => dispatch(actions.addToCart(productObject, isAuth))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Category);