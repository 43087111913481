import React from 'react';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import PriceFormat from '../UI/PriceFormat/PriceFormat';
import ProductQuantity from './ProductQuantity/ProductQuantity';
import { Link } from 'react-router-dom';
import ProductCartButton from './ProductCartButton/ProductCartButton';
const Product = (props) => {
	return (
		<>
			<Link to={'/product/' + props.id}>
				<Image src={props.image} className="d-block mx-auto" fluid />
			</Link>
			<Link to={'/product/' + props.id}><h5 className="text-center">{props.name}</h5></Link>
			<div className="d-flex flex-wrap align-items-center justify-content-around px-3">
				<PriceFormat>{props.price}</PriceFormat>
				<ProductQuantity changeQty={props.onChangeQty} {...props} />
				<ProductCartButton
					addToCart={props.addToCart}
					cartObject={{
						id: props.id, name: props.name, image: props.image, qty: props.qty, price: props.price
					}}
					isAuth={props.isAuth}
				>
					<FontAwesomeIcon icon={faCartPlus} />
				</ProductCartButton>
			</div>
		</>
	);
};

export default Product;