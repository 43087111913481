import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	loading: false,
	sale: null,
	purchased: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.PURCHASE_START: return updateObject(state, { loading: true });
		case actionTypes.PURCHASE_SUCCESS:
			const newSale = updateObject(action.saleData, { id: action.saleId });
			return updateObject(state, {
				loading: false,
				purchased: true,
				sale: newSale
			});
		case actionTypes.PURCHASE_FAILED: return updateObject(state, { loading: false, error: action.error });
		case actionTypes.PURCHASE_RESET: return initialState;
		default:
			return state;
	}
}

export default reducer;