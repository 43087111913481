import React from 'react';
import { Button, Spinner, Badge } from 'react-bootstrap';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CartBoxButton = (props) => {
	let countItems = 0;
	props.cartItems.forEach((item) => {
		countItems += item.qty;
	});

	let iconCart = (
		<Button onClick={props.toggleCartBox} variant="outline-success">
			<FontAwesomeIcon icon={faShoppingCart} /> {' '}<Badge variant="success">{countItems}</Badge>
		</Button>
	);
	if (props.loading) {
		iconCart = (
			<Button disabled variant="outline-success">
				<Spinner size="sm" animation="border" variant="success" />
			</Button>
		);
	}
	return iconCart;
};

export default CartBoxButton;