export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const CART_START = 'CART_START';
export const CART_START_ITEM_UPDATE = 'CART_START_ITEM_UPDATE';
export const CART_SET = 'CART_SET';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_ALERT = 'CART_ALERT';
export const DISMISS_CART_ALERT = 'DISMISS_CART_ALERT';
export const CART_EMPTY = 'CART_EMPTY';

export const AUTH_VALIDATION_START = 'AUTH_VALIDATION_START';
export const AUTH_VALIDATION_SUCCESS = 'AUTH_VALIDATION_SUCCESS';
export const AUTH_VALIDATION_FAIL = 'AUTH_VALIDATION_FAIL';

export const AUTH_NEED_VALIDATION = 'AUTH_NEED_VALIDATION';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';

export const SET_AUTH_REDIRECT_AUTH = 'SET_AUTH_REDIRECT_AUTH';

export const FETCH_ADDRESSES_START = 'FETCH_ADDRESSES_START';
export const ADD_NEW_ADDRESS = 'ADD_NEW_ADDRESS';
export const SHOW_MODAL_NEW_ADDRESS = 'SHOW_MODAL_NEW_ADDRESS';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const FETCH_ADDRESSES_FAILED = 'FETCH_ADDRESSES_FAILED';

export const SET_PAYMENT_ADDRESS = 'SET_PAYMENT_ADDRESS';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
export const TOGGLE_PAYMENT_DATA_SHIPPING = 'TOGGLE_PAYMENT_DATA_SHIPPING';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

export const CHECKOUT_TOTALS_START = 'CHECKOUT_TOTALS_START';
export const CHECKOUT_TOTALS_SUCCESS = 'CHECKOUT_TOTALS_SUCCESS';
export const CHECKOUT_TOTALS_FAIL = 'CHECKOUT_TOTALS_FAIL';
export const CHECKOUT_RESET = 'CHECKOUT_RESET';

export const CHECKOUT_SHIPPINGS_START = 'CHECKOUT_SHIPPINGS_START';
export const CHECKOUT_SHIPPINGS_SUCCESS = 'CHECKOUT_SHIPPINGS_SUCCESS';
export const CHECKOUT_SHIPPINGS_FAIL = 'CHECKOUT_SHIPPINGS_FAIL';

export const PURCHASE_START = 'PURCHASE_START';
export const PURCHASE_SUCCESS = 'PURCHASE_SUCCESS';
export const PURCHASE_FAILED = 'PURCHASE_FAILED';
export const PURCHASE_RESET = 'PURCHASE_RESET';