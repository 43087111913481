import React, { Component } from 'react';
import './styles/core.scss'
import classes from './App.module.scss';

import Layout from './containers/Layout/Layout';
import Homepage from './containers/Homepage/Homepage';
import Auth from './containers/Auth/Auth';
import Page from './containers/Page/Page';
import Category from './containers/Category/Category';
import Product from './containers/Product/Product';
import Account from './containers/Account/Account';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import Register from './containers/Auth/Register/Register';
import Cart from './containers/Cart/Cart';
import Search from './containers/Search/Search';
import Checkout from './containers/Checkout/Checkout';
import CheckoutSuccess from './containers/Checkout/CheckoutSuccess/CheckoutSuccess';

class App extends Component {

	constructor(props) {
		super(props);
		// console.log('[App.js] constructor');
		const tokenExists = this.props.onTryAutoSignup();

		this.state = {
			tokenExists: tokenExists
		}
	}

	componentDidMount() {
		// console.log('[App.js] componentDidMount');
		const isAuth = this.props.isAuthenticated || this.state.tokenExists;
		this.props.initCart(isAuth);
	}

	componentDidUpdate(prevProps) {
		if (this.props.cartItems.length > 0 && prevProps.cartItems !== this.props.cartItems){
			this.props.getTotals(this.props.cartItems);
		}
		if (this.props.shippingMethod && prevProps.shippingMethod !== this.props.shippingMethod){
			this.props.getTotals(this.props.cartItems, this.props.shippingMethod);
		}
	}

	render() {
		
		let routes = (
			<Switch>
				<Route path="/account" component={Account} />
				<Route path="/register" component={Register} />
				<Route path="/auth" component={Auth} />
				<Route path="/category/:id" component={Category} />
				<Route path="/page/:id" component={Page} />
				<Route path="/product/:id" component={Product} />
				<Route path="/cart" component={Cart} />
				<Route path="/search" component={Search} />
				<Route path="/checkout/success" component={CheckoutSuccess} />
				<Route path="/checkout" exact component={Checkout} />
				<Route path="/" exact component={Homepage} />
				<Redirect to="/" />
			</Switch>
		);

		return (
			<div className={classes.App}>
				<Layout>
					{routes}
				</Layout>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.token !== null,
		cartItems: state.cart.items,
		shippingMethod: state.checkout.shippingMethod,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onTryAutoSignup: () => dispatch(actions.authCheckState()),
		initCart: (isAuth) => dispatch(actions.initCart(isAuth)),
		getTotals: (cartItems, shippingRateId) => dispatch(actions.checkoutTotals(cartItems, shippingRateId))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(App);