import React from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';

const Input = (props) => {

	let inputElement = null;

	const inputClasses = [];
	const invalid = !props.valid && props.shouldValidate && props.touched;
	const valid = props.valid && props.shouldValidate && props.touched;

	if (invalid) {
		inputClasses.push("is-invalid");
	} else if (valid) {
		inputClasses.push("is-valid");
	}

	switch (props.elementType) {
		case ('input'):
			inputElement = <Form.Control
				onChange={props.changed}
				className={inputClasses.join(' ')}
				{...props.elementConfig}
				value={props.value}
			/>;
			break;
		case ('textarea'):
			inputElement = <Form.Control
				as="textarea"
				onChange={props.changed}
				className={inputClasses.join(' ')}
				{...props.elementConfig}
				value={props.value}
			/>;
			break;
		case ('select'):
			inputElement = (
				<Form.Control
					as="select"
					onChange={props.changed}
					className={inputClasses.join(' ')}
					value={props.value}
				>
					{props.elementConfig.options.map(option => {
						return (
							<option key={option.value} value={option.value}>{option.displayValue}</option>
						);
					})}
				</Form.Control>
			);
			break;
		case ('radio'):
			const radios = props.elementConfig.options.map((option, num) => (
				<Form.Check
					key={option.value}
					custom
					type="radio"
					name={props.name}
					id={props.id + '-' + num}
					onChange={props.changed}
					label={option.label}
					value={option.value}
				/>
			));
			inputElement = (
				radios
			);
			break;
		case ('checkbox'):
			const checkboxes = props.elementConfig.options.map((option, num) => (
				<Form.Check
					key={option.value}
					custom
					type="checkbox"
					name={props.name}
					id={props.id + '-' + num}
					onChange={props.changed}
					label={option.label}
					value={option.value}
					checked={option.checked}
				/>
			));
			inputElement = (
				checkboxes
			);
			break;
		case ('password'):
			inputElement = (
				<InputGroup className={invalid ? 'is-invalid' : valid ? 'is-valid' : null}>
					<Form.Control
						onChange={props.changed}
						className={inputClasses.join(' ')}
						{...props.elementConfig}
						value={props.value}
					/>
					<InputGroup.Append>
						<Button onClick={() => props.togglePassword(props.id)} variant="outline-secondary">
							{props.elementConfig.type === 'password' ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
						</Button>
					</InputGroup.Append>
				</InputGroup>
			);
			break;
		case 'recaptcha':
			inputElement = (
				<ReCAPTCHA
					ref={props.elementConfig.recaptchaRef}
					sitekey="6LeMq7MZAAAAAGxKw5bQHrYYScgdcqdfMcnCxYJZ"
				/>
			);
			break;
		default:
			inputElement = (
				<p>Input not found!</p>
			);
	}

	let feedbackText = null;

	if (invalid && props.errorText) {
		feedbackText = <div className="invalid-feedback">{props.errorText}</div>
	} else if (valid && props.validText) {
		feedbackText = <div className="valid-feedback">{props.validText}</div>
	}

	return (
		<Form.Group>
			{props.elementConfig.label ? <Form.Label>{props.elementConfig.label}</Form.Label> : null}
			{inputElement}
			{props.instruction
				?
				<Form.Text className="text-muted">
					{props.instruction}
				</Form.Text>
				: null}
			{feedbackText}

		</Form.Group>
	);
};

export default Input;