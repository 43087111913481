import React from 'react';
import { Card } from 'react-bootstrap';
import Input from '../../../UI/Input/Input';
const PaymentMethod = (props) => {

	let payments = null;

	const options = props.methods.map(method => (
		{
			label: method.label,
			value: method.code
		}
	));

	payments = (
		<Input
			elementType="radio"
			name="paymentmethodinput"
			id="paymentmethodinput"
			elementConfig={{
				options: options,
				label: 'Scegli un metodo di pagamento',
			}}
			changed={(event) => props.methodChange(event.target.value)}
			shouldValidate="false"
		/>
	)

	return (
		<Card>
			<Card.Body>
				{payments}
			</Card.Body>
		</Card>
	);
};

export default PaymentMethod;