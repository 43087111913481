import Axios from 'axios';

const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD;

const instance = Axios.create({
	baseURL: baseUrl,
	headers: {
		get: {
			'Content-Type': 'application/json'
		},
		post: {
			'Content-Type': 'application/json'
		}
	}
});

export default instance;