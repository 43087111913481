import React from 'react';
import classes from './CartBoxSide.module.scss';
import { Row, Col, Image, Button } from 'react-bootstrap';
import PriceFormat from '../../UI/PriceFormat/PriceFormat';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CartBoxSide = (props) => {
	const assignedClasses = [];
	assignedClasses.push(classes.Cartbox);
	if (props.showCartBox) {
		assignedClasses.push(classes.open);
	}
	let cartItems = <div className="text-center">Carrello vuoto!</div>;
	if (props.cartItems.length > 0) {
		cartItems = props.cartItems.map(item => {
			return (
				<Row className="mb-2" key={item.id}>
					<Col xs="4">
						<Image src={item.image} className="d-block mx-auto" fluid />
					</Col>
					<Col xs="8" className="small">
						<div>
							{item.name}
						</div>
						<div>
							<PriceFormat>{item.price}</PriceFormat>
						</div>
						<div>
							Qty: {item.qty}
						</div>
					</Col>
				</Row>
			);
		});
	}
	return (

		<div className={assignedClasses.join(' ')}>
			<div className={classes.container}>
				<Button variant="outline-secondary" className="border-0" onClick={props.toggleCartBox}><FontAwesomeIcon icon={faTimes} /></Button>
				<div className="mx-auto text-center">
					Totale Carrello <PriceFormat>{props.totalPrice}</PriceFormat>
				</div>
				<div className="text-center mb-3">
					<LinkContainer to="/cart" >
						<Button onClick={props.toggleCartBox} className="w-100" variant="secondary" size="sm" >Carrello</Button>
					</LinkContainer>
				</div>
				{cartItems}
			</div>
		</div>
	);
};

export default CartBoxSide;