import React, { Component } from 'react';
import ProductList from '../ProductList/ProductList';

class Search extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchString: this.searching()
		}
	}

	searching = () => {
		const query = new URLSearchParams(this.props.location.search);
		const searchString = query.get('s');

		const queryParams = [];
		queryParams.push('model=' + searchString);
		queryParams.push('productDescriptions.name=' + searchString);
		queryParams.push('productDescriptions.description=' + searchString);
		queryParams.push('categories.categoryDescriptions.name=' + searchString);
		return queryParams.join('&');
	}


	componentDidUpdate(prevProps) {
		if (prevProps.location.search && prevProps.location.search !== this.props.location.search) {
			this.setState({
				searchString: this.searching()
			});
		}
	}


	render() {
		// console.log('[Render]', 'Search.js', this.state.searchString);
		return (
			<ProductList path={"/products?" + this.state.searchString} />
		);
	}
}

export default Search;