import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classes from './Footer.module.scss';


const Footer = (props) => {
	let menuItems = null;
	if (props.menuBottom) {
		menuItems = props.menuBottom.map(item => (
			<li key={item.id + '_' + item.type}><Link to={"/" + item.type + "/" + item.id}>{item.name}</Link></li>
		));
	}

	return (
		<footer className={classes.Footer + ' bg-dark py-4 mt-4'}>
			<Container>
				<Row>
					<Col lg="3" className="text-light">
						<b>Webag SNC</b><br />
						via Livorno 54, 50142 Firenze (FI) <br />
						<a href="tel:+39055123456789">Tel. +39 055 123456789</a><br />
						<a href="mailto:info@webag.it">info@webag.it</a><br />
					</Col>
					<Col lg="3">
						<ul className="list-unstyled">
							{menuItems}
						</ul>
					</Col>
					<Col lg="3">
						<ul className="list-unstyled">
							{props.isAuth
								? <>
									<li><Link to="/account">Il tuo account</Link></li>
									<li><Link to="/account/orders">I tuoi ordini</Link></li>
									<li><Link to="/account/addresses">I tuoi indirizzi</Link></li>
								</>
								: <>
									<li><Link to="/auth">Login</Link></li>
									<li><Link to="/register">Registrazione</Link></li>
								</>}
						</ul>
					</Col>
					<Col lg="3">

					</Col>
				</Row>
				<hr />
				<Row>
					<Col className="text-light text-center small">
						Copyright © 2020 · All Rights Reserved - CCIAA Firenze n. REA-FI 0000 - P.IVA 06546170488 - Privacy - Gestisci Cookie - Cookie Policy | Powered by Vendieconsegna.it
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;