import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';

const AccountSummary = (props) => {
	return (
		<Row>
			<LinkContainer to={props.match.path + '/orders'}>
				<Col as="a" md="3">
					<Card>
						<Card.Body>
							<FontAwesomeIcon size="2x" icon={faShoppingBasket} />	<span className="h5">I miei ordini</span>
				    </Card.Body>
					</Card>
				</Col>
			</LinkContainer>
			<LinkContainer to={props.match.path + '/addresses'}>
				<Col as="a" md="3">
					<Card>
						<Card.Body>
							<FontAwesomeIcon size="2x" icon={faAddressCard} />{' '}<span className="h5">Indirizzi</span>
						</Card.Body>
					</Card>
				</Col>
			</LinkContainer>
		</Row>
	);
};

export default AccountSummary;