import React, { Fragment } from 'react';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.module.scss';
import Backdrop from '../../UI/Backdrop/Backdrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';


const sideDrawer = (props) => {
	//... conditionally classes
	let attachedClasses = [classes.SideDrawer, classes.Close];
	if (props.open) {
		attachedClasses = [classes.SideDrawer, classes.Open];
	}

	return (
		<Fragment>
			<Backdrop show={props.open} clicked={props.closed} />
			<div className={attachedClasses.join(' ')}>
				<Button className="d-block ml-auto" variant="outline-dark" onClick={props.closed} type="button"><FontAwesomeIcon icon={faTimes} /></Button>
				<nav>
					<NavigationItems menuItems={props.menuItems} clickItems={props.closed} />
				</nav>
			</div>
		</Fragment>
	);
};

export default sideDrawer;