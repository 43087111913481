import React, { Component } from 'react';
import Axios from '../../axios-symfony';
import Product from '../../components/Product/Product';
import { changeQuantityInput, setDefaultQty } from '../../shared/utility';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Spinner from '../../components/UI/Spinner/Spinner';
import * as actions from '../../store/actions/index';

class ProductList extends Component {
	state = {
		products: null,
		loading: false
	}

	componentDidMount() {
		// console.log('[ProductList] componentDidMount');
		this.loadData();
	}

	componentDidUpdate(prevProps) {
		// console.log('[ProductList] componentDidUpdate');
		if (prevProps.path && prevProps.path !== this.props.path) {
			this.loadData();
		}
	}

	loadData() {
		this.setState({
			loading: true
		});
		Axios.get(this.props.path)
			.then(response => {
				// console.log(response);
				this.setState({
					products: setDefaultQty(response.data['hydra:member']),
					loading: false
				});
			})
			.catch(error => {
				console.log(error);
			});
	}

	onChangeQuantityHandler = (product, variation) => {
		// console.log(id);
		// console.log(variation);

		this.setState({
			products: changeQuantityInput(this.state.products, product.id, variation)
		})
	}

	render() {
		let product_list = null;
		if (this.state.products) {
			product_list = this.state.products.map(product => (
				<Col sm="6" lg="4" xl="3" className="py-3" key={product.id}>
					<Product isAuth={this.props.isAuthenticated} onChangeQty={this.onChangeQuantityHandler} addToCart={this.props.onAddToCart} {...product} />
				</Col>
			));
		}
		if (this.state.loading) {
			product_list = (
				<Spinner />
			);
		}
		return (
			<Row>
				{product_list}
			</Row>
		);
	}
}
const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.token !== null
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onAddToCart: (productObject, isAuth) => dispatch(actions.addToCart(productObject, isAuth))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductList);