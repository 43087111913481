import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Image } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import PriceFormat from '../../components/UI/PriceFormat/PriceFormat';
import CheckoutSummary from '../../components/Checkout/CheckoutSummary';
import GlobalSpinner from '../../components/UI/Spinner/Spinner';
import ProductQuantity from '../../components/Product/ProductQuantity/ProductQuantity';
import ProductRemove from '../../components/Product/ProductRemove/ProductRemove';
import LoadingOverlay from '../../components/UI/LoadingOverlay/LoadingOverlay';
import CheckoutButton from '../../components/Checkout/CheckoutButton/CheckoutButton';

class Cart extends Component {

	onChangeQuantityHandler = (product, variation) => {
		if ((product.qty + variation) > 0) {
			this.props.onAddToCart({ id: product.id, name: product.name, image: product.image, qty: variation, price: product.price }, this.props.isAuthenticated);
		} else {
			this.props.onCartRemove(product.id, this.props.isAuthenticated);
		}
	}

	render() {
		let cartItems = <div className="text-center">Carrello vuoto!</div>;
		if (this.props.cartLoading && this.props.cartItems.length === 0) {
			cartItems = <GlobalSpinner />
		}
		if (this.props.cartItems.length > 0) {
			cartItems = this.props.cartItems.map(item => {

				let loadingOverlay = null;
				if (item.loading) {
					loadingOverlay = (
						<LoadingOverlay />
					);
				}
				return (
					<Row key={item.id}>
						<Col>
							{loadingOverlay}
							<Row className="mb-2 align-items-center">
								<Col lg={{ span: 2, offset: 0 }} md={{ span: 3, offset: 0 }} xs={{ span: 10, offset: 1 }}>
									<Image src={item.image} className="d-block mx-auto" fluid />
								</Col>
								<Col md={{ span: 2, offset: 0 }} xs={{ span: 10, offset: 1 }} >
									<div>
										{item.name}
									</div>
									<div>
										<small className="text-muted">Prezzo Articolo:</small> <PriceFormat>{item.price}</PriceFormat>
									</div>
								</Col>
								<Col md="2" xs={{ span: 4, offset: 1 }} >
									<ProductQuantity changeQty={this.onChangeQuantityHandler} {...item} />
								</Col>
								<Col md="2" xs="3" >
									<PriceFormat>{+item.price * +item.qty}</PriceFormat>
								</Col>
								<Col md="2" xs="3" >
									<ProductRemove productRemove={this.props.onCartRemove} id={item.id} isAuth={this.props.isAuthenticated} />
								</Col>
							</Row>
						</Col>
					</Row>
				);
			});
		}
		return (
			<div>
				<Row>
					<Col lg="9">
						{cartItems}
					</Col>
					<Col lg="3">
						{this.props.cartItems.length > 0 ?
							<Fragment>
								<CheckoutSummary
									totalShipping={this.props.totalShipping}
									totalTaxes={this.props.totalTaxes}
									subTotal={this.props.subTotal}
									totalPrice={this.props.totalPrice}
									loading={this.props.checkoutLoading}
								/>
								<CheckoutButton />
							</Fragment>
							: null}
					</Col>
				</Row>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.token !== null,
		cartItems: state.cart.items,
		cartLoading: state.cart.loading,
		checkoutLoading: state.checkout.loading,
		totalShipping: state.checkout.totalShipping,
		totalTaxes: state.checkout.totalTaxes,
		subTotal: state.checkout.subTotal,
		totalPrice: state.checkout.total
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onAddToCart: (productObject, isAuth) => dispatch(actions.addToCart(productObject, isAuth)),
		onCartRemove: (productId, isAuth) => dispatch(actions.cartRemove(productId, isAuth))
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Cart);