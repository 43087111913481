import React from 'react';
import { Card, Badge, Button } from 'react-bootstrap';
import AddressSelect from '../AddressSelect/AddressSelect';
import PaymentMethod from './PaymentMethod/PaymentMethod';

const PaymentInfo = (props) => {
	return (
		<Card className="mb-3">
			<Card.Header as="h3">
				<Badge variant="secondary">2</Badge> Informazioni fatturazione
			</Card.Header>
			<Card.Body>
				<AddressSelect loading={props.loading} selectId="radiopayment" addressChange={props.addressChange} addresses={props.addresses} />
				{props.isSetPaymentAddress ? <PaymentMethod methods={props.paymentMethods} methodChange={props.methodChange} /> : null}
			</Card.Body>
			<Card.Footer>
				{!props.isChecked ? <Button variant="secondary" size="sm" onClick={() => props.addAddress(true)} >Aggiungi nuovo indirizzo</Button> : null}
			</Card.Footer>
		</Card>
	);
};

export default PaymentInfo;