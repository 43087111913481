import React, { Fragment, Component } from 'react';

import { Alert } from 'react-bootstrap';

const withErrorHandler = (WrappedComponent, Axios) => {
	return class extends Component {

		state = {
			error: null
		}
		constructor(props) {
			super(props);

			this.reqInterceptor = Axios.interceptors.request.use(req => {
				this.setState({
					error: null
				});
				return req;
			});
			this.resInterceptor = Axios.interceptors.response.use(res => res, error => {
				// console.log(error.response);
				this.setState({
					error: error.response.data.message
				});
				return Promise.reject(error);
			});
		}

		componentWillUnmount() {
			// console.log('[withErrorHandler.js componentWillUnmount]', this.reqInterceptor, this.resInterceptor)
			Axios.interceptors.request.eject(this.reqInterceptor);
			Axios.interceptors.response.eject(this.resInterceptor);
			this.props.onResetError();
		}

		errorConfirmedHandler = () => {
			this.setState({ error: null });
		}

		render() {
			return (
				<Fragment>
					{
						this.state.error ? (
							<Alert variant="danger"
								onClose={this.errorConfirmedHandler}
								dismissible
							>{this.state.error}</Alert>
						) : null
					}
					<WrappedComponent {...this.props} />
				</Fragment>
			);
		}
	}
};

export default withErrorHandler;