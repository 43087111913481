
import * as actionTypes from './actionTypes';
import Axios from '../../axios-symfony';

export const showModalNewAddress = (show) => {
	return {
		type: actionTypes.SHOW_MODAL_NEW_ADDRESS,
		show: show
	}
}

export const togglePaymentDataShipping = (addressId) => {
	return {
		type: actionTypes.TOGGLE_PAYMENT_DATA_SHIPPING,
		addressId: addressId,
	}
}

export const setShippingMethod = (methodId) => {
	return {
		type: actionTypes.SET_SHIPPING_METHOD,
		methodId: methodId
	}
}

export const setPaymentMethod = (methodId) => {
	return {
		type: actionTypes.SET_PAYMENT_METHOD,
		methodId: methodId
	}
}

export const checkoutReset = () => {
	return {
		type: actionTypes.CHECKOUT_RESET,
	}
}

const checkoutTotalsStart = () => {
	return {
		type: actionTypes.CHECKOUT_TOTALS_START
	}
}
const checkoutTotalsSuccess = (totals) => {
	return {
		type: actionTypes.CHECKOUT_TOTALS_SUCCESS,
		totals: totals
	}
}
const checkoutTotalsFail = () => {
	return {
		type: actionTypes.CHECKOUT_TOTALS_FAIL
	}
}

export const checkoutTotals = (cartObject, shippingRateId = null) => {
	return (dispatch) => {
		// console.log(cartObject);
		dispatch(checkoutTotalsStart());
		const uriProducts = cartObject.map((product) => (
			'products[' + product.id + ']=' + product.qty
		))

		if (shippingRateId) {
			uriProducts.push('shipping_rate=' + shippingRateId);
		}

		Axios.get('/totals?' + uriProducts.join('&'))
			.then(response => {
				// console.log(response);
				dispatch(checkoutTotalsSuccess(response.data));
			})
			.catch(error => {
				dispatch(checkoutTotalsFail());
			})
	}
}

const checkoutShippingsStart = () => {
	return {
		type: actionTypes.CHECKOUT_SHIPPINGS_START
	}
}
const checkoutShippingsSuccess = (addressId, shippings) => {
	return {
		type: actionTypes.CHECKOUT_SHIPPINGS_SUCCESS,
		addressId: addressId,
		shippings: shippings
	}
}
const checkoutShippingsFail = () => {
	return {
		type: actionTypes.CHECKOUT_SHIPPINGS_FAIL
	}
}

// export const setShippingAddress = (addressId) => {
// 	return {
// 		type: actionTypes.SET_SHIPPING_ADDRESS,
// 		addressId: addressId,
// 	}
// }

// export const setPaymentAddress = (addressId) => {
// 	return {
// 		type: actionTypes.SET_PAYMENT_ADDRESS,
// 		addressId: addressId,
// 	}
// }

export const setPaymentAddress = (addressId) => {
	return (dispatch) => {
		Axios.get('/payments')
			.then(response => {
				dispatch({
					type: actionTypes.SET_PAYMENT_ADDRESS,
					addressId: addressId,
					payments: response.data['hydra:member']
				});
			})
	}
}


export const setShippingAddress = (addressId, cartObject, zoneId) => {
	return (dispatch) => {
		dispatch(checkoutShippingsStart());
		const uriProducts = cartObject.map((product) => (
			'products[' + product.id + ']=' + product.qty
		)).join('&');
		Axios.get('/shippings?' + uriProducts + '&zone=' + zoneId)
			.then(response => {
				// console.log(response);
				dispatch(checkoutShippingsSuccess(addressId, response.data['hydra:member']));
			})
			.catch(error => {
				console.log(error);
				dispatch(checkoutShippingsFail());
			})
	}
}