import * as actionTypes from './actionTypes';
import Axios from '../../axios-symfony';
import { initCart, emptyCart } from './cartAction';

const authStart = () => {
	return {
		type: actionTypes.AUTH_START
	}
}
const authSuccess = (token, userId) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		token: token,
		userId: userId
	}
}

const authFail = (error, violations = null) => {
	return {
		type: actionTypes.AUTH_FAIL,
		error: error,
		violations: violations,
	}
}
const authValidationStart = () => {
	return {
		type: actionTypes.AUTH_VALIDATION_START
	}
}
const authValidationSuccess = (token, userId) => {
	return {
		type: actionTypes.AUTH_VALIDATION_SUCCESS,
		token: token,
		userId: userId
	}
}

const authValidationFail = (error) => {
	return {
		type: actionTypes.AUTH_VALIDATION_FAIL,
		error: error
	}
}

const authNeedValidation = (userId) => {
	return {
		type: actionTypes.AUTH_NEED_VALIDATION,
		userId: userId
	}
}

const checkAuthTimeout = (expirationTime) => {
	return dispatch => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime * 1000);
	}
}

export const setAuthRedirectPath = path => {
	return {
		type: actionTypes.SET_AUTH_REDIRECT_AUTH,
		path: path
	}
}

export const authLogout = () => {
	return {
		type: actionTypes.AUTH_LOGOUT
	}
}

export const authResetError = () => {
	return {
		type: actionTypes.AUTH_RESET_ERROR
	}
}

export const register = (registerData) => {
	return dispatch => {
		dispatch(authStart());
		Axios.post('/customers', registerData)
			.then(response => {
				localStorage.setItem('userId', response.data.id);
				localStorage.setItem('needValidation', true);
				dispatch(authNeedValidation(response.data.id));
			})
			.catch(error => {
				console.log(error);
				dispatch(authFail(true, error.response.data.violations));
			});
	}
}

export const auth = (email, password) => {
	return dispatch => {
		dispatch(authStart());
		const authData = {
			username: email,
			password: password
		}
		const url = "/login_check";
		Axios.post(url, authData,
		)
			.then(response => {
				// console.log(response);
				const expirationIn = 3600;
				const expirationDate = new Date(new Date().getTime() + expirationIn * 1000);
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('expirationDate', expirationDate);
				localStorage.setItem('userId', response.data.userId);
				localStorage.removeItem('needValidation');
				dispatch(authSuccess(response.data.token, response.data.userId));
				dispatch(checkAuthTimeout(expirationIn));
				dispatch(initCart(true));
			})
			.catch((error) => {
				// console.log(error);
				//auth fail with not validate, must set needValidation.
				dispatch(authFail(true));
			});
	}
}

export const logout = () => {
	return dispatch => {
		localStorage.removeItem('token');
		localStorage.removeItem('expirationDate');
		localStorage.removeItem('userId');
		dispatch(authLogout());
		dispatch(emptyCart());
	}
}

export const authVerifyCode = (userId, confirmationToken) => {
	return dispatch => {
		const authVerifyData = {
			confirmationToken: confirmationToken,
			customerId: userId
		};
		dispatch(authValidationStart());
		Axios.post('/customers/confirm', authVerifyData)
			.then(response => {
				console.log(response);
				const expirationIn = 3600;
				const expirationDate = new Date(new Date().getTime() + expirationIn * 1000);
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('expirationDate', expirationDate);
				localStorage.setItem('userId', response.data.userId);
				localStorage.removeItem('needValidation');
				dispatch(authValidationSuccess(response.data.token, response.data.userId));
				dispatch(checkAuthTimeout(expirationIn));
				dispatch(initCart(true));
			})
			.catch((error) => {
				console.log(error);
				dispatch(authValidationFail(error));
			});
	}
}

export const authCheckState = () => {
	return dispatch => {
		const token = localStorage.getItem('token');
		// console.log(token);
		if (!token) {
			const needValidation = localStorage.getItem('needValidation');
			if (!needValidation) {
				dispatch(logout());
			} else {
				const userIdValidation = localStorage.getItem('userId');
				dispatch(authNeedValidation(userIdValidation));
			}
		} else {
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			if (expirationDate <= new Date()) {
				dispatch(logout());
			} else {
				const userId = localStorage.getItem('userId');
				const authReturn = dispatch(authSuccess(token, userId));
				const expireTime = (expirationDate.getTime() - new Date().getTime()) / 1000;
				dispatch(checkAuthTimeout(expireTime));
				return authReturn.token !== null;
			}
		}
	}
}