import React, { Component } from 'react';

import ProductList from '../ProductList/ProductList';

class Homepage extends Component {
	componentDidUpdate() {
		console.log('[Homepage.js] componentDidUpdate');
	}

	render() {

		return (
			<ProductList path="/products" />
		);
	}
}

export default Homepage;