import React from 'react';
import { Dropdown, Badge, Row, Col, Image, Card, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import PriceFormat from '../UI/PriceFormat/PriceFormat';
import { LinkContainer } from 'react-router-bootstrap';
import CustomScroll from 'react-custom-scroll';


const CartBox = (props) => {

	let cartItems = <div className="text-center">Carrello vuoto!</div>;
	let countItems = 0;
	if (props.cartItems.length > 0) {
		cartItems = props.cartItems.map(item => {
			countItems += item.qty;
			return (
				<Row className="mb-2" key={item.id}>
					<Col xs="4">
						<Image src={item.image} className="d-block mx-auto" fluid />
					</Col>
					<Col xs="8" className="small">
						<div>
							{item.name}
						</div>
						<div>
							<PriceFormat>{item.price}</PriceFormat>
						</div>
						<div>
							Qty: {item.qty}
						</div>
					</Col>
				</Row>
			);
		});
	}

	let iconCart = (
		<Dropdown.Toggle onClick={props.toggleCartBox} variant="outline-success">
			<FontAwesomeIcon icon={faShoppingCart} /> {' '}<Badge variant="success">{countItems}</Badge>
		</Dropdown.Toggle>
	);
	if (props.loading) {
		iconCart = (
			<Button disabled variant="outline-success">
				<Spinner size="sm" animation="border" variant="success" />
			</Button>
		);
	}

	return (
		<Dropdown show={props.showCartBox}>
			{iconCart}
			<Dropdown.Menu className="pt-0 pb-0 border-0 d-flex" style={{ width: '300px', height: '350px' }}>
				<CustomScroll flex="1">
					<Card>
						{
							props.cartItems.length > 0
								?
								<Card.Header>
									<div className="mx-auto text-center">
										Totale Carrello <PriceFormat>{props.totalPrice}</PriceFormat>
									</div>
									<div className="text-center">
										<LinkContainer to="/cart" >
											<Button onClick={props.toggleCartBox} className="w-100" variant="secondary" size="sm" >Carrello</Button>
										</LinkContainer>
									</div>
								</Card.Header>
								: null
						}
						<Card.Body>
							{cartItems}
						</Card.Body>
					</Card>
				</CustomScroll>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default CartBox;