import React, { Component, Fragment } from 'react';

import { Route } from 'react-router-dom';
import AccountSummary from '../../components/Account/AccountSummary';

class Account extends Component {
	render() {
		return (
			<Fragment>
				<h1 className="h2">Il mio account</h1>
				<AccountSummary {...this.props} />
				<Route
					path={this.props.match.path + '/addresses'}
				// component={Address}
				/>
				<Route
					path={this.props.match.path + '/orders'}
				// component={Orders}
				/>
			</Fragment>
		);
	}
}

export default Account;