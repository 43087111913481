import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddressForm from '../AddressForm/AddressForm';

const AddressModal = (props) => {
	return (
		<Modal show onHide={() => props.onShowModal(false)}>
			<Modal.Body>
				<AddressForm loading={props.loading} controls={props.addressControls} inputChange={props.inputChange} />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => props.onShowModal(false)}>Chiudi</Button>
				<Button onClick={props.newAddressForm} variant="primary">Salva</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddressModal;