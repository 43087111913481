import React, { Component } from 'react';
import Axios from '../../axios-symfony';
import { Row, Col, Image } from 'react-bootstrap';
import * as actions from '../../store/actions/index';
import PriceFormat from '../../components/UI/PriceFormat/PriceFormat';
import { connect } from 'react-redux';
import { updateObject } from '../../shared/utility';
import ProductQuantity from '../../components/Product/ProductQuantity/ProductQuantity';
import ProductCartButton from '../../components/Product/ProductCartButton/ProductCartButton';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Facebook } from 'react-social-sharing'
import Spinner from '../../components/UI/Spinner/Spinner';

class Product extends Component {

	state = {
		loadedProduct: null,
	}
	componentDidMount() {
		// console.log('[Category.js] componentDidMount');
		this.loadData();
	}
	componentDidUpdate(prevProps) {
		// console.log('[Category.js] componentDidUpdate');
		if (prevProps.match.params.id && prevProps.match.params.id !== this.props.match.params.id) {
			this.loadData();
		}
	}

	loadData() {
		const id = +this.props.match.params.id;
		// console.log(id);
		Axios.get('/products/' + id)
			.then(response => {
				// console.log(response);
				// console.log(response.data.products);
				this.setState({
					loadedProduct: updateObject(response.data, { qty: 1 }),
				})
			});
	}

	onChangeQuantityHandler = (product, variation) => {
		// console.log(id);
		this.setState({
			loadedProduct: updateObject(this.state.loadedProduct, {
				qty: this.state.loadedProduct.qty + variation
			})
		})
	}

	render() {
		let product = <Spinner />;
		if (this.state.loadedProduct) {
			product = (
				<Row>
					<Col lg="6">
						<Image src={this.state.loadedProduct.image} className="d-block mx-auto" fluid />
					</Col>
					<Col lg="6">
						<h1 className="h2">{this.state.loadedProduct.name}</h1>
						<PriceFormat className="font-weight-light h3">{this.state.loadedProduct.price}</PriceFormat>
						<hr />
						<div className="mb-4">
							Quantità
							<ProductQuantity changeQty={this.onChangeQuantityHandler} {...this.state.loadedProduct} />
						</div>
						<ProductCartButton
							addToCart={this.props.onAddToCart}
							cartObject={{
								id: this.state.loadedProduct.id, name: this.state.loadedProduct.name, image: this.state.loadedProduct.image, qty: this.state.loadedProduct.qty, price: this.state.loadedProduct.price
							}}
							isAuth={this.props.isAuth}
						>
							<FontAwesomeIcon icon={faCartPlus} /> Aggiungi al carrello
						</ProductCartButton>
						<hr />
						<h4>Dettagli del prodotto</h4	>
						<div>{this.state.loadedProduct.description}</div>
						<hr />
						<b>Condividi</b><br />
						<Facebook link={window.location.href}></Facebook>
					</Col>
				</Row>
			);
		}
		return product;
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.token !== null
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onAddToCart: (productObject, isAuth) => dispatch(actions.addToCart(productObject, isAuth))
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Product);