import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import AuthForm from './AuthForm/AuthForm';

class Auth extends Component {

	render() {
		return (
			<Row>
				<Col lg={{ span: 8, offset: 2 }}>
					<Card>
						<Card.Body>
							<h4 className="h4 text-gray-900 mb-4">Benvenuto! Effettua il login.</h4>
							<AuthForm />
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default Auth;