import React from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const ProductQuantity = (props) => {

	const product = { id: props.id, name: props.name, image: props.image, qty: props.qty, price: props.price };
	return (
		<InputGroup size="sm" style={{ width: '100px' }}>
			<InputGroup.Prepend>
				<Button variant="outline-secondary" onClick={() => props.changeQty(product, -1)}><FontAwesomeIcon icon={faMinus} /></Button>
			</InputGroup.Prepend>
			<Form.Control className="text-center" value={props.qty} readOnly />
			<InputGroup.Append>
				<Button variant="outline-secondary" onClick={() => props.changeQty(product, 1)} ><FontAwesomeIcon icon={faPlus} /></Button>
			</InputGroup.Append>
			{props.update}
		</InputGroup>
	);
};

export default ProductQuantity;