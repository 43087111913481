import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initState = {
	items: [],
	alert: [],
	loading: false
}

const addItem = (state, action, productIndex) => {
	if (productIndex < 0) {
		return state.items.concat(action.productObject);
	}

	const product = updateObject(state.items[productIndex], {
		...state.items[productIndex],
		qty: Number(state.items[productIndex].qty) + Number(action.productObject.qty),
		loading: false
	});

	const updateItems = [...state.items];

	updateItems[productIndex] = product;
	return updateItems;
}

const updateLocalStorage = (cartState) => {
	localStorage.setItem('cart', JSON.stringify(cartState));
}

const randomNumer = () => {
	return Math.random().toString().substr(2, 5);
}

const cartReducer = (state = initState, action) => {
	let newItems;
	switch (action.type) {
		case actionTypes.ADD_TO_CART_SUCCESS:
			newItems = addItem(state, action, action.productIndex);

			const newCartStateEx = updateObject(state, {
				items: newItems,
				loading: false,
			});
			if (!action.isAuth) {
				updateLocalStorage({ items: newItems });
			}
			return newCartStateEx;
		case actionTypes.CART_START: return updateObject(state, { loading: true });
		case actionTypes.CART_START_ITEM_UPDATE:
			const product = updateObject(state.items[action.productIndex], {
				...state.items[action.productIndex],
				loading: true,
			});
			const updateItems = [...state.items];
			updateItems[action.productIndex] = product;

			return updateObject(state, { items: updateItems, loading: true });
		case actionTypes.CART_REMOVE_ITEM:
			const items = [
				...state.items
			];

			items.splice(action.productIndex, 1);
			const newCartRem = updateObject(state, {
				items: items
			});
			if (!action.isAuth) {
				updateLocalStorage({ items: items });
			}
			return newCartRem;
		case actionTypes.DISMISS_CART_ALERT:
			const alertItems = state.alert.filter((item) => item.index !== action.alertIndex);
			return updateObject(state, { alert: alertItems });
		case actionTypes.CART_ALERT:
			return updateObject(state, { alert: state.alert.concat({ type: action.alertType, index: randomNumer() }) })
		case actionTypes.CART_SET:
			return updateObject(state, { ...action.cartObject, loading: false });
		case actionTypes.CART_EMPTY:
			return updateObject(state, { items: [] });
		default:
			return state;
	}

}
export default cartReducer;