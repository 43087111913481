
import * as actionTypes from './actionTypes';
import Axios from '../../axios-symfony';

const fetchAddressesSuccess = addresses => {
	return {
		type: actionTypes.FETCH_ADDRESSES_SUCCESS,
		addresses: addresses
	}
}

const fetchAddressesFailed = error => {
	return {
		type: actionTypes.FETCH_ADDRESSES_FAILED,
		error: error
	}
}

const fetchAddressesStart = () => {
	return {
		type: actionTypes.FETCH_ADDRESSES_START
	}
}

const newAddress = (newAddressData) => {
	return {
		type: actionTypes.ADD_NEW_ADDRESS,
		newAddressData: newAddressData
	}
}

export const fetchAddresses = () => {
	return (dispatch, getState) => {
		dispatch(fetchAddressesStart());
		const currentState = getState();
		// console.log(currentState);
		const userId = currentState.auth.userId;
		const token = currentState.auth.token;
		const config = {
			headers: { Authorization: `Bearer ${token}` }
		};
		Axios.get('/customers/' + userId + '/addresses', config)
			.then(response => {
				console.log(response);
				const fetchedAddresses = [];
				for (let key in response.data['hydra:member']) {
					fetchedAddresses.push({
						...response.data['hydra:member'][key],
					});
				}
				dispatch(fetchAddressesSuccess(fetchedAddresses));
			})
			.catch(err => {
				dispatch(fetchAddressesFailed(err));
			});
	}
}


export const addNewAddress = (addressData) => {
	return (dispatch, getState) => {
		dispatch(fetchAddressesStart());
		const currentState = getState();
		const token = currentState.auth.token;
		const config = {
			headers: { Authorization: `Bearer ${token}` }
		};
		
		Axios.post('/addresses', addressData, config)
			.then(response => {
				console.log(response);
				dispatch(newAddress(response.data));
			})
			.catch(error => {
				dispatch(fetchAddressesFailed(error.response.data.violations));
			})
	}
}
