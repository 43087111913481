import React, { Component } from 'react';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Top from '../../components/Top/Top';
import Footer from '../../components/Footer/Footer';
import { connect } from 'react-redux';
import Axios from '../../axios-symfony';
import * as actions from '../../store/actions/index';
import { Container } from 'react-bootstrap';
import CartAlert from '../../components/CartAlert/CartAlert';
import { withRouter } from 'react-router-dom';
import LayoutSidebar from './LayoutSidebar/LayoutSidebar';
import CartBoxSide from '../../components/CartBox/CartBoxSide/CartBoxSide';
import classes from './Layout.module.scss';

const { sidebar, cartBox } = window['runConfig'];

class Layout extends Component {

	constructor(props) {
		super(props);
		const query = new URLSearchParams(props.location.search);
		const searchString = query.get('s');
		this.state = {
			searchValue: searchString ? searchString : '',
			showSideDrawer: false,
			menuItems: null,
			menuBottom: null,
			shouldShowAlert: false,
			showAlertCart: false,
			showCartBox: false
		}
	}

	componentDidMount() {
		Axios.get('/menus?position=top')
			.then(response => {
				const items = response.data['hydra:member'][0]['menuItems'];
				const menuItems = items.map(item => (
					{
						id: item[item.type].id,
						name: item[item.type].name,
						type: item.type
					}
				));

				this.setState({
					menuItems: menuItems
				});
			})
			.catch(error => {

			});
		Axios.get('/menus?position=bottom')
			.then(response => {
				const items = response.data['hydra:member'][0]['menuItems'];
				const menuBottom = items.map(item => (
					{
						id: item[item.type].id,
						name: item[item.type].name,
						type: item.type
					}
				));

				this.setState({
					menuBottom: menuBottom
				});
			})
			.catch(error => {

			});
	}

	sideDrawerClosedHandler = () => {
		this.setState({ showSideDrawer: false })
	}

	sideDrawerToggleHandler = () => {
		this.setState((prevState) => {
			return (
				{ showSideDrawer: !prevState.showSideDrawer }
			)
		})
	}

	searchChangeHandler = event => {

		this.setState({
			searchValue: event.target.value
		});

	}

	searchHandler = () => {
		const queryParams = [];
		queryParams.push('s=' + this.state.searchValue);

		const queryString = queryParams.join('&');

		this.props.history.push({
			pathname: '/search',
			search: '?' + queryString
		});
	}

	enterPressHandler = (event) => {
		if (event.keyCode === 13) {
			this.searchHandler();
		}
	}

	toggleCartBoxHandler = () => {
		this.setState({
			showCartBox: !this.state.showCartBox
		});
	}

	render() {
		let content = null;
		if (sidebar) {
			content = <LayoutSidebar content={this.props.children} />;
		} else {
			content = this.props.children;
		}

		//if Cartbox Type is Side
		const assignedClasses = [];
		assignedClasses.push(classes.Layout);
		if (cartBox === 'side') {
			if (this.state.showCartBox) {
				assignedClasses.push(classes.openCartBox);
			}
		}
		return (
			<>
				<div className={assignedClasses.join(' ')}>
					<Top />
					<Toolbar
						searchValue={this.state.searchValue}
						searchChange={this.searchChangeHandler}
						keyPress={this.enterPressHandler}
						search={this.searchHandler}
						cartItems={this.props.cartItems}
						totalPrice={this.props.totalPrice}
						isAuth={this.props.isAuthenticated}
						menuItems={this.state.menuItems}
						logout={this.props.onLogout}
						loadingCart={this.props.loadingCart}
						opened={this.sideDrawerToggleHandler}
						open={this.state.showSideDrawer}
						showCartBox={this.state.showCartBox}
						toggleCartBox={this.toggleCartBoxHandler}
						cartBoxType={cartBox}
					/>
					<SideDrawer
						// isAuth={this.props.isAuthenticated}
						open={this.state.showSideDrawer}
						menuItems={this.state.menuItems}
						closed={this.sideDrawerClosedHandler} />
					<Container className="mt-4">
						{content}
					</Container>
					<CartAlert dismiss={this.props.onDismissCartAlert} alerts={this.props.alertCart} />
					<Footer menuBottom={this.state.menuBottom} isAuth={this.props.isAuthenticated} />
				</div>
				{cartBox === 'side'
					? <CartBoxSide
						showCartBox={this.state.showCartBox}
						cartItems={this.props.cartItems}
						toggleCartBox={this.toggleCartBoxHandler}
						totalPrice={this.props.totalPrice}
					/>
					: null}
			</>
		);
	}
};


const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.token !== null,
		cartItems: state.cart.items,
		loadingCart: state.cart.loading,
		alertCart: state.cart.alert,
		totalPrice: state.checkout.totalProducts
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onLogout: () => dispatch(actions.logout()),
		onDismissCartAlert: (index) => dispatch(actions.dismissCartAlert(index))
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));