import React, { Component } from 'react';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import InputList from '../../../components/UI/InputList/InputList';
import { updateObject, checkViolations } from '../../../shared/utility';
import { checkValidity } from '../../../shared/validation';
import Axios from '../../../axios-symfony';
import Spinner from '../../../components/UI/Spinner/Spinner';

class ContactPage extends Component {

	state = {
		loading: false,
		sendForm: false,
		controls: {
			name: {
				elementType: 'input',
				elementConfig: {
					label: 'Nome',
					type: 'text',
					placeholder: 'Nome',
				},
				rules: {
					required: true,
					minLength: 3,
				},
				errorText: 'Il nome deve avere almeno 3 caratteri',
				valid: false,
				touched: false,
				value: ''
			},
			lastname: {
				elementType: 'input',
				elementConfig: {
					label: 'Cognome',
					type: 'text',
					placeholder: 'Cognome',
				},
				rules: {
					required: true,
					minLength: 3,
				},
				errorText: 'Il cognome deve avere almeno 3 caratteri',
				valid: false,
				touched: false,
				value: ''
			},
			phone: {
				elementType: 'input',
				elementConfig: {
					label: 'Telefono',
					type: 'text',
					placeholder: 'Telefono',
				},
				rules: {
					required: true,
					minLength: 6,
				},
				errorText: 'Il telefono deve avere almeno 6 caratteri',
				valid: false,
				touched: false,
				value: ''
			},
			email: {
				elementType: 'input',
				elementConfig: {
					label: 'Indirizzo Email',
					type: 'email',
					placeholder: 'Indirizzo Email',
				},
				rules: {
					required: true,
					isEmail: true,
				},
				errorText: 'Formato Email non valido',
				valid: false,
				touched: false,
				value: ''
			},
			message: {
				elementType: 'textarea',
				elementConfig: {
					label: 'Messaggio',
					placeholder: 'Messaggio',
					rows: 7
				},
				rules: {
					required: true,
					minLength: 3,
				},
				errorText: 'Il messaggio deve avere almeno 3 caratteri',
				valid: false,
				touched: false,
				value: ''
			},
			recaptcha: {
				elementType: 'recaptcha',
				elementConfig: {
					recaptchaRef: React.createRef()
				},
				rules: {
					required: true
				}
			}
		}
	}

	submitFormHandler = (event) => {
		event.preventDefault();
		const formData = {};

		for (let name in this.state.controls) {
			if (name !== 'recaptcha') {
				formData[name] = this.state.controls[name].value;
			}
		}

		formData['recaptcha'] = this.state.controls.recaptcha.elementConfig.recaptchaRef.current.getValue();
		this.setState({
			loading: true
		});
		Axios.post('/contacts', formData)
			.then(response => {
				this.setState({
					loading: false,
					sendForm: true
				});
			})
			.catch(error => {
				const updatedControls = checkViolations([], error.response.data.violations, this.state.controls);
				if (updatedControls) {
					this.setState({
						loading: false,
						controls: updatedControls
					});
				}
			});

	}

	inputChangedHandler = (event, controlName) => {
		const updatedControls = updateObject(this.state.controls, {
			[controlName]: updateObject(this.state.controls[controlName], {
				value: event.target.value,
				valid: checkValidity(event.target.value, this.state.controls[controlName].rules),
				touched: true
			})
		});

		this.setState({
			controls: updatedControls
		})
	}

	render() {
		let form = <InputList controls={this.state.controls} inputChange={this.inputChangedHandler} />

		return (
			<Row>
				<Col lg={{ span: 6 }}>
					<Card className="h-100">
						<Card.Body>
							<div dangerouslySetInnerHTML={{ __html: this.props.description }}></div>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={{ span: 6 }}>
					<Card className="h-100">
						<Card.Body>
							<h1 className="h4 text-secondary mb-4">{this.props.title}</h1>
							{this.state.sendForm
								? <h3>Grazie, modulo inviato con successo, sarai ricontattato prima possibile!</h3>
								: this.state.loading
									? <Spinner />
									:
									<Form onSubmit={this.submitFormHandler}>
										{form}
										<Button variant="primary" type="submit">
											Invia
								    </Button>
									</Form>
							}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default ContactPage;