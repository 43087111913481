export const updateObject = (oldObject, updateProperties) => {
	return {
		...oldObject,
		...updateProperties
	}
};

export const changeQuantityInput = (products, id, qty) => {

	const productIndex = products.findIndex(p => {
		return p.id === id;
	});

	const resQty = products[productIndex].qty + qty;

	if (resQty <= 0) {
		return products;
	}

	const product = updateObject(products[productIndex], {
		...products[productIndex],
		qty: resQty
	});

	const updateProducts = [...products];
	updateProducts[productIndex] = product;
	return updateProducts;
};

export const setDefaultQty = (products) => {
	const productsModified = products.map(product => (
		{
			...product,
			qty: 1
		}
	));
	return productsModified;
}

export const checkViolations = (prevViolations, currentViolations, controls) => {
	if ((currentViolations && !prevViolations) || (currentViolations && prevViolations && (prevViolations !== currentViolations))) {
		let updatedControls = {
			...controls
		};

		currentViolations.forEach(function (item) {
			updatedControls = updateObject(updatedControls, {
				[item.propertyPath]: updateObject(updatedControls[item.propertyPath], {
					touched: true,
					valid: false,
					errorText: item.message
				})
			});
		});

		return updatedControls;
	}
	return false;
}