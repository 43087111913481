import React from 'react';
import Input from '../../UI/Input/Input';


const AddressSelect = (props) => {

	let inputAddresses = <p>Non sono presenti indirizzi</p>;
	if (props.addresses && props.addresses.length > 0) {
		const options = props.addresses.map(address => {
			return {
				label: address.firstname + ' ' + address.lastname + ' - ' + address.address + ', ' + address.postcode + ', ' + address.city + ', ' + address.zone.name,
				value: address.id
			};
		});
		inputAddresses = <Input
			elementType="radio"
			name={props.selectId}
			id={props.selectId}
			elementConfig={{
				options: options,
				label: 'Scegli indirizzo esistente',
			}}
			changed={(event) => props.addressChange(event)}
			shouldValidate="false"
		/>
	}
	
	return (
		<div style={{ height: '150px', overflowY: 'scroll' }}>
			{inputAddresses}
		</div>
	);
};

export default AddressSelect;