import React from 'react';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import { Container, Row, Col } from 'react-bootstrap';
import SearchBox from '../../SearchBox/SearchBox';
import CartBox from '../../CartBox/CartBox';
import CustomerNav from '../../CustomerNav/CustomerNav';
import CartBoxSideButton from '../../CartBox/CartBoxSide/CartBoxSideButton/CartBoxSideButton';

const toolbar = (props) => {
	return (
		<>
			<Container>
				<Row className="align-items-center">
					<Col className="p-1" lg={{ order: 1, span: 4 }} xs={{ order: 2, span: 6 }}>
						<Logo />
					</Col>
					<Col xs={{ order: 4, span: 2 }} className="d-block d-lg-none p-1">
						<DrawerToggle sideDrawerOpen={props.open} clicked={props.opened} />
					</Col>
					<Col className="p-1" lg={{ order: 2, span: 3 }} xs={{ order: 5, span: 10 }}>
						<SearchBox press={props.keyPress} change={props.searchChange} click={props.search} searchValue={props.searchValue} />
					</Col>
					<Col className="p-1" lg={{ order: 3, span: 3 }} xs={{ order: 1, span: 3 }}>
						<CustomerNav logout={props.logout} isAuth={props.isAuth} />
					</Col>

					<Col className="p-1" lg={{ order: 4, span: 2 }} xs={{ order: 3, span: 3 }}>
						{props.cartBoxType === 'standard'
							?
							<CartBox
								toggleCartBox={props.toggleCartBox}
								showCartBox={props.showCartBox}
								loading={props.loadingCart}
								cartItems={props.cartItems}
								totalPrice={props.totalPrice} />
							: null}
						{props.cartBoxType === 'side'
							? <CartBoxSideButton
								toggleCartBox={props.toggleCartBox}
								loading={props.loadingCart}
								cartItems={props.cartItems} />
							: null}
					</Col>

				</Row>
			</Container>
			<div className="bg-primary">
				<Container>
					<nav className="d-none d-lg-block">
						<NavigationItems menuItems={props.menuItems} />
					</nav>
				</Container>
			</div>
		</>
	);
};

export default toolbar;
