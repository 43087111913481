import React, { Component } from 'react';
import Axios from '../../axios-symfony';
import { Col, Row, Image } from 'react-bootstrap';
import Spinner from '../../components/UI/Spinner/Spinner';
import { Facebook } from 'react-social-sharing/dist-modules';
import ContactPage from './ContactPage/ContactPage';

class Page extends Component {

	state = {
		loadedPage: null,
	}
	componentDidMount() {
		this.loadData();
	}
	componentDidUpdate(prevProps) {
		if (prevProps.match.params.id && prevProps.match.params.id !== this.props.match.params.id) {
			this.loadData();
		}
	}

	loadData() {
		const id = this.props.match.params.id;
		Axios.get('/pages/' + id)
			.then(response => {
				// console.log(response);
				// console.log(response.data.Pages);
				this.setState({
					loadedPage: response.data,
				})
			});
	}
	render() {
		let page = <Spinner />;
		if (this.state.loadedPage) {
			switch (this.state.loadedPage.type) {
				case 'contact':
					page = <ContactPage title={this.state.loadedPage.name} description={this.state.loadedPage.description} />
					break;
				default:
					page = (
						<Row>
							<Col lg="12">
								{this.state.loadedPage.image ? <Image src={this.state.loadedPage.image} className="d-block mx-auto" fluid /> : null}
								<h1 className="h2">{this.state.loadedPage.name}</h1>
								<div dangerouslySetInnerHTML={{ __html: this.state.loadedPage.description }}></div>
								<hr />
								<b>Condividi</b><br />
								<Facebook link={window.location.href}></Facebook>
							</Col>
						</Row>
					);
			}
		}
		return page;
	}
}
export default Page;
