import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import cartReducer from './store/reducers/cartReducer';
import authReducer from './store/reducers/authReducer';
import checkoutReducer from './store/reducers/checkout';
import addressReducer from './store/reducers/address';
import saleReducer from './store/reducers/sale';
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
	checkout: checkoutReducer,
	address: addressReducer,
	cart: cartReducer,
	auth: authReducer,
	sale: saleReducer,
});

const store = createStore(
	rootReducer, composeEnhancers(applyMiddleware(thunk))
);


const app = (
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
