import React from 'react';
import { Button } from 'react-bootstrap';
import Paypal from '../Payment/Paypal/Paypal';

const SaleButton = (props) => {
	let button = <Button onClick={props.createSale} disabled={props.disabled} className="border-radius-t-0 w-100">{props.children}</Button>;
	if (props.paymentMethod === 'paypal') {
		const ppMethod = props.paymentMethods.find(method => method.code === 'paypal');
		button = <Paypal clientId={ppMethod.data.value} amount={props.total} createSale={props.createSale} />
	}
	return button;
};

export default SaleButton;