import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const CustomerNav = (props) => {

	let dropdown = (
		<Dropdown>
			<Dropdown.Toggle variant="outline-success">
				<span className="d-none d-lg-inline">Accedi {' '}</span><FontAwesomeIcon icon={faUserAlt} />
			</Dropdown.Toggle>

			<Dropdown.Menu style={{ width: '300px' }}>
				<LinkContainer to="/auth">
					<Dropdown.Item>Login</Dropdown.Item>
				</LinkContainer>
				<LinkContainer to="/register">
					<Dropdown.Item>Registrazione</Dropdown.Item>
				</LinkContainer>
			</Dropdown.Menu>
		</Dropdown>
	);

	if (props.isAuth) {
		dropdown = (
			<Dropdown>
				<Dropdown.Toggle variant="outline-success">
					<span className="d-none d-lg-inline">Il mio account {' '}</span><FontAwesomeIcon icon={faUserAlt} />
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ width: '300px' }}>
					<LinkContainer to="/account">
						<Dropdown.Item>Account</Dropdown.Item>
					</LinkContainer>
					<Dropdown.Item as="button" onClick={props.logout}>Logout</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	}

	return dropdown;
};

export default CustomerNav;