import React, { Component } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import AuthForm from '../../Auth/AuthForm/AuthForm';
import { Link } from 'react-router-dom';

class CheckoutLogin extends Component {
	render() {
		return (
			<Row>
				{/* <Col lg={{ span: 6 }} className="mb-3">
					<Card>
						<Card.Body>
							<h4 className="">Sei nuovo sul sito?</h4>
							<p className="text-muted small">Puoi effettuare il tuo acquisto anche senza un account</p>
							<Button variant="primary">Pagamento Ospite</Button>
						</Card.Body>
					</Card>
				</Col> */}
				<Col lg={{ span: 6 }} className="mb-3">
					<Card>
						<Card.Body>
							<h4 className="">Sei nuovo sul sito?</h4>
							<p className="text-muted small">Effettua la registrazione</p>
							<Link className="btn btn-primary" to="/register">Registrati subito</Link>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={{ span: 6 }}>
					<Card>
						<Card.Body>
							<h4 className="h4 text-gray-900 mb-4">Sono già cliente - Accedi e paga</h4>
							<AuthForm />
						</Card.Body>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default CheckoutLogin;