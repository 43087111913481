import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { LinkContainer } from 'react-router-bootstrap';

const Top = () => {
	return (
		<Navbar bg="secondary" variant="dark" className="p-1 d-none d-lg-block">
			<Container className="justify-content-between">
				<Nav className="mr-auto">
					<LinkContainer to="/something">
						<Nav.Link ><FontAwesomeIcon icon={faMapMarkerAlt} /> Verifica aree di consegna</Nav.Link>
					</LinkContainer>
				</Nav>
				<Nav className="ml-auto">
					<Nav.Link href="tel:0550640180"><FontAwesomeIcon icon={faPhoneAlt} />  055 0640180</Nav.Link>
				</Nav>
			</Container>
		</Navbar>
	);
};

export default Top;