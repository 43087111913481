import React from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBox = (props) => {
	return (
		<InputGroup className="">
			<InputGroup.Prepend>
				<Button onClick={props.click} variant="outline-secondary" className="border-right-0 border-radius-l-5"><FontAwesomeIcon icon={faSearch} ></FontAwesomeIcon></Button>
			</InputGroup.Prepend>
			<FormControl type="text"
				value={props.searchValue}
				onChange={props.change}
				onKeyDown={props.press}
				className="border-left-0 border-secondary border-radius-r-5"
				placeholder="Cerca tra i prodotti" />
		</InputGroup>
	);
};

export default SearchBox;